import { ApiResponse } from 'apisauce'
import { navigate } from 'gatsby'
import { UpdateProfileParams } from 'models/user/user'
import { Api } from 'services/api-integrations/api'
import { getGeneralApiProblem } from 'services/api-integrations/api-problem'
import { GetSuppliersResult } from 'supplier.types'
import { CreateSupplierParams, UpdateSupplierParams } from 'supplier/supplier'

import * as Types from './api-integrations/api.types'

const API_PAGE_SIZE = 50

export class SummaryApi extends Api {
  /**
   * ANCHOR Summary of Order
   */
  async summaryOfOrder(data: any) {
    // const [userData] = useRecoilState(userAtom)

    const response: ApiResponse<any> = await this.apisauce.post(
      `/listsummary/oforder`,
      data.body,
      {
        headers: data.headers,
        params: data.params,
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      console.log(problem)
      if (problem && 'kind' in problem && problem.kind == 'unauthorized') {
        navigate('/auth/login')
      } else {
        throw new Error(`${response.data?.message}`)
      }
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw }
    } catch {
      throw new Error('bad-data')
    }
  }

  /**
   * ANCHOR Monthly Summary
   */
  async summaryOfMonthly(data: any) {
    // const [userData] = useRecoilState(userAtom)

    const response: ApiResponse<any> = await this.apisauce.post(
      `/listsummary/ofmonthly`,
      data.body,
      {
        headers: data.headers,
        params: data.params,
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        throw new Error(`${response.data?.message}`)
      }
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw }
    } catch {
      throw new Error('bad-data')
    }
  }
}
