import { User } from 'models/user/user'
import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()
// ANCHOR User State
export const initialState: User = {
  country_id: '',
  country_name: '',
  email: '',
  photo_path: '',
  profile_name: '',
  role_id: '',
  role_name: '',
  token: '',
  username: '',
}

export const menuAtom = atom({
  key: 'menuState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const userAtom = atom({
  key: 'userState',
  default: initialState,
  effects_UNSTABLE: [persistAtom],
})
