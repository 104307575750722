import React, { useEffect, useState } from 'react'

import { ProCard } from '@ant-design/pro-components'
import { Tabs } from 'antd'

import DraggableMedia from 'components/dragable-media'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { AqlApi } from 'services/aql-api'
import { MasterDataApi } from 'services/master-data-api'

import FileEditor from './file-editor'
import FileUploader from './file-uploader'

interface FrontPageImageProps {
  reportStatus: '1' | '2' | '3' | '4' | '5'
}

const FrontPageImage: React.FC<FrontPageImageProps> = ({ reportStatus }) => {
  const [userData] = useRecoilState(userAtom)
  const [tabList, setTabList] = useState([])
  const [imageList, setImageList] = useState<any>({})
  const [currentItemData, setCurrentItemData] = useState<any>({})
  const [currentSelectItem, setCurrentSelectItem] = useState<any>()
  const [activeKey, setActiveKey] = useState('8')
  const [previewActiveKey, setPreviewActiveKey] = useState('1')
  const [inspectionId, setInspectionId] = useState<any>()

  const tabTypeMutation = useMutation(async (data: any) => {
    const api = useApi(MasterDataApi)
    return api.dropdown(data)
  })

  const imageListMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.ItemListImage(data)
  })

  useEffect(() => {
    const pageParams = new URLSearchParams(location.search)
    const inspection_id = pageParams.get('id')
    setInspectionId(inspection_id)

    tabTypeMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        option: 'photo_inspection_page',
      },
    })

    reloadMediaList()
  }, [])

  useEffect(() => {
    if (!imageListMutation.isError && imageListMutation.data) {
      console.log(imageListMutation.data)
      if (imageListMutation.data.kind == 'ok') {
        let result = imageListMutation.data.data.result
        if (result.list_item?.length) {
          delete result.list_item
          console.log(result)
          setImageList(result)
        } else {
          setImageList({})
        }
      }
    }
  }, [imageListMutation.data, imageListMutation.isError])

  useEffect(() => {
    if (!tabTypeMutation.isError && tabTypeMutation.data) {
      if (tabTypeMutation.data.kind == 'ok') {
        setTabList(tabTypeMutation.data.data.result)
      }
    }
  }, [tabTypeMutation.data, tabTypeMutation.isError])

  /**
   * ANCHOR Functions
   */
  const reloadMediaList = () => {
    const pageParams = new URLSearchParams(location.search)
    const inspection_id = pageParams.get('id')
    let item_id: any = pageParams.get('item_id')
    item_id = parseInt(item_id)
    // const report_status = pageParams.get('report_status')
    // const rs = { inline: 1, final: 2, refinal: 3 }

    if (inspection_id && !isNaN(parseInt(inspection_id))) {
      imageListMutation.mutate({
        headers: {
          'x-access-token': userData.token,
        },
        params: {
          inspection_id: inspection_id,
          report_status: reportStatus,
        },
        body: {
          // list_item_id: [item_id],
        },
      })
    }
  }

  const onSelectItem = (item: any) => {
    console.log('onselectitem', item)
    setCurrentSelectItem(item)
    setPreviewActiveKey('1')
  }

  const onUpdateSuccess = () => {
    console.log('update')
    reloadMediaList()
  }

  return (
    <Tabs
      type="card"
      tabPosition={'left'}
      tabBarStyle={{ width: 150 }}
      size={'small'}
      onTabClick={(activeKey) => {
        setCurrentSelectItem(null)
        setActiveKey(activeKey)
      }}
    >
      {tabList.map((e: any) => {
        return (
          <Tabs.TabPane
            tab={
              e.value.length > 14 ? e.value.substring(0, 14) + '...' : e.value
            }
            key={e.id}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: 5, flex: 1 }}>
                <div style={{ padding: 5, margin: '5px, 0' }}>{e.value}</div>
                <DraggableMedia
                  mediaType="photo"
                  photoType="photo_inspection_page"
                  items={
                    Object.keys(imageList).length
                      ? imageList['' + e.id].list_photo
                      : []
                  }
                  onSelectItem={onSelectItem}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  // flex: 1,
                  width: 350,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  borderLeft: '1px solid #eaeaea',
                  padding: 5,
                  paddingLeft: 10,
                  // backgroundColor: 'red',
                }}
              >
                <Tabs
                  defaultActiveKey="1"
                  onChange={() => {}}
                  type="card"
                  style={{ width: '100%' }}
                  activeKey={previewActiveKey}
                  onTabClick={(pActiveKey) => {
                    setPreviewActiveKey(pActiveKey)
                  }}
                >
                  <Tabs.TabPane tab="Preview & Edit" key="1">
                    <FileEditor
                      mediaType="photo"
                      item={currentSelectItem}
                      onUpdateSuccess={onUpdateSuccess}
                      photoType={'photo_inspection_page'}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Upload" key="2">
                    <FileUploader
                      mediaType="photo"
                      mediaGroup={activeKey}
                      embededReportStatus={reportStatus}
                      inspectionId={inspectionId}
                      onSuccess={onUpdateSuccess}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </Tabs.TabPane>
        )
      })}
    </Tabs>
  )
}

export default FrontPageImage
