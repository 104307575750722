export const generateAqlInspectionFilter = (params: any) => {
  const {
    po_number,
    status,
    profile_name,
    country,
    customer_name,
    supplier_name,
    factory,
    actual_shipping_date,
    inspection_date_inline,
    inspection_date_final,
    inspection_date_refinal,
    active_status_name,
    supplier_id,
    customer_id,
    item_no,
  } = params
  let where = []
  console.log("generateAqlInspectionFilter : " + JSON.stringify(params))
  if (po_number) {
    where.push({
      fields: 'po_number',
      value: po_number,
      type: 'string',
    })
  }

  if (status) {
    where.push({
      fields: 'status',
      value: status,
      type: 'string',
    })
  }

  if (profile_name) {
    where.push({
      fields: 'profile_name',
      value: profile_name,
      type: 'string',
    })
  }

  if (country) {
    where.push({
      fields: 'country',
      value: country,
      type: 'string',
    })
  }

  if (factory) {
    where.push({
      fields: 'factory',
      value: factory,
      type: 'string',
    })
  }

  if (customer_name) {
    where.push({
      fields: 'customer_name',
      value: customer_name,
      type: 'string',
    })
  }

  if (supplier_name) {
    where.push({
      fields: 'supplier_name',
      value: supplier_name,
      type: 'string',
    })
  }

  if (actual_shipping_date) {
    where.push({
      fields: 'actual_shipping_date',
      value: actual_shipping_date,
      type: 'string',
    })
  }

  if (inspection_date_inline) {
    where.push({
      fields: 'inspection_date_inline',
      value: inspection_date_inline,
      type: 'string',
    })
  }

  if (inspection_date_final) {
    where.push({
      fields: 'inspection_date_final',
      value: inspection_date_final,
      type: 'string',
    })
  }

  if (inspection_date_refinal) {
    where.push({
      fields: 'inspection_date_refinal',
      value: inspection_date_refinal,
      type: 'string',
    })
  }

  if (active_status_name) {
    where.push({
      fields: 'active_status_name',
      value: active_status_name,
      type: 'string',
    })
  }

  if (supplier_id) {
    where.push({
      fields: 'supplier_id',
      value: supplier_id,
      type: 'int',
    })
  }

  if (customer_id) {
    where.push({
      fields: 'customer_id',
      value: customer_id,
      type: 'int',
    })
  }

  if (item_no) {
    where.push({
      fields: 'item_no',
      value: item_no,
      type: 'string',
    })
  }

  return where
}
