import * as React from 'react'

import { ProCard, ProDescriptions } from '@ant-design/pro-components'
import { Tooltip } from 'antd'

interface ItemMediasProps {}

const ItemMedias: React.FC<ItemMediasProps> = () => {
  return (
    <ProCard>
      <ProDescriptions
        // actionRef={actionRef}
        // bordered
        formProps={{
          onValuesChange: (e, f) => console.log(f),
        }}
        title="Item Details"
        // request={async () => {

        // }}
        // editable={{}}
        columns={[
          {
            title: 'P.O. No.',
            key: 'po_number',
            dataIndex: 'po_number',
            // copyable: true,
            // ellipsis: true,
          },
          {
            title: 'Item No.',
            key: 'item_no',
            dataIndex: 'item_no',
          },
          {
            title: 'Color',
            key: 'color',
            dataIndex: 'color',
          },
          {
            title: 'Description',
            key: 'item_description',
            dataIndex: 'item_description',
          },
          {
            title: 'Vendor',
            key: 'vendor',
            dataIndex: 'vendor',
          },
          {
            title: 'Type',
            key: 'type',
            dataIndex: 'type',
          },
        ]}
      ></ProDescriptions>
    </ProCard>
  )
}

export default ItemMedias
