export const generateUserFilter = (params: any, activeTab: string) => {
  const { status, profile_name, role_name, country_name, email } = params
  let where = []
  if (role_name) {
    where.push({
      fields: 'role_id',
      value: parseInt(role_name),
      type: 'int',
    })
  } else {
    // if (activeTab == 'users') {
    //   where.push({
    //     fields: 'role_id',
    //     value: [1, 3, 4, 5, 6, 7, 8, 10],
    //     type: 'array',
    //   })
    // } else if (activeTab == 'customers') {
    //   where.push({
    //     fields: 'role_id',
    //     value: 2,
    //     type: 'int',
    //   })
    // } else if (activeTab == 'suppliers') {
    //   where.push({
    //     fields: 'role_id',
    //     value: 9,
    //     type: 'int',
    //   })
    // }
  }

  if (profile_name) {
    where.push({
      fields: 'profile_name',
      value: profile_name,
      type: 'string',
    })
  }

  if (email) {
    where.push({
      fields: 'email',
      value: email,
      type: 'string',
    })
  }

  if (country_name && country_name != '1') {
    where.push({
      fields: 'country_id',
      value: parseInt(country_name),
      type: 'int',
    })
  }

  if (status) {
    where.push({
      fields: 'status',
      value: status,
      type: 'string',
    })
  }
  return where
}
