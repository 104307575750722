import React, { useEffect, useState } from 'react'

import {
  BetaSchemaForm,
  ProCard,
  ProDescriptions,
} from '@ant-design/pro-components'
import { Alert, Button, message } from 'antd'

import { profileFields } from 'configs/profile-fields'
import { DataItem, resetPasswordFields } from 'configs/reset-password-fields'
import { UpdateProfileParams } from 'models/user/user'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { UserApi } from 'services/user-api'

const EditProfile = () => {
  const [displayName, setDisplayName] = useState()
  const [userData, setUserData] = useRecoilState(userAtom)
  const updateMutation = useMutation(async (data: UpdateProfileParams) => {
    const userApi = useApi(UserApi)
    return userApi.updateProfile(data)
  })

  const updateProfile = async () => {
    updateMutation.mutate({
      headers: {
        token: userData.token,
      },
      payload: {
        display_name: displayName,
        status: 'A',
      },
    })
  }

  const onFieldsChange = async (e: any) => {
    if (e.length) {
      setDisplayName(e[0].value)
    } else {
      if (displayName) {
        await updateProfile()
      }
    }
  }

  const onChangePasswordFinish = async (values: any) => {
    updateMutation.mutate({
      headers: {
        token: userData.token,
      },
      payload: values,
    })
    console.log(values)
  }

  useEffect(() => {
    if (!updateMutation.isError) {
      const result = updateMutation.data
      if (result?.kind == 'ok') {
        message.success('Success')
        setUserData({ ...userData, profile_name: displayName })
      } else {
        // message.error('Error!!!')
      }
    }
  }, [updateMutation.data, updateMutation.isError])

  return (
    <ProCard
      title="Edit Profile"
      split={'vertical'}
      style={{ marginTop: 8 }}
      bordered
      headerBordered
    >
      <ProCard title={'Profile'} colSpan={10} layout="default">
        <ProDescriptions
          // actionRef={actionRef}
          formProps={{
            // onValuesChange: (_e, f) => console.log(f),
            onFieldsChange: onFieldsChange,
          }}
          request={async () => {
            const { profile_name, country_name, role_name } = userData
            return Promise.resolve({
              success: true,
              data: { profile_name, country_name, role_name },
            })
          }}
          editable={{}}
          // layout={'vertical'}
          size={'small'}
          columns={profileFields}
          column={1}
          contentStyle={{ fontWeight: 'bold' }}
        />
      </ProCard>

      <ProCard title={'Password'} colSpan={14} layout="default">
        {updateMutation.isError && (
          <Alert
            message={'Error: ' + updateMutation?.error?.message}
            type="error"
            style={{ marginBottom: 20 }}
          />
        )}
        <BetaSchemaForm<DataItem>
          layoutType="Form"
          submitter={{
            submitButtonProps: {
              loading: updateMutation.isLoading,
            },
          }}
          labelCol={{ span: 8 }}
          layout={'horizontal'}
          onFinish={onChangePasswordFinish}
          columns={resetPasswordFields}
        />
      </ProCard>
    </ProCard>
  )
}

export default EditProfile
