import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const aqlInspectionListState = atom({
  key: 'aqlInspectionsState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const aqlInspectionListFilterState = atom({
  key: 'aqlInspectionListFilterState',
  default: 'Show All',
})

export const aqlInspectionState = selector({
  key: 'aqlInspectionState',
  get: ({ get }) => {
    const allAqlInspectionList = get(aqlInspectionListState)
    const aql_inspection_id = get(aqlInspectionListFilterState)
    if (aql_inspection_id != 'Show All') {
      return allAqlInspectionList.filter((e: any) => e.id == aql_inspection_id)
    } else {
      return {}
    }
  },
})
