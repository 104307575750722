import React, { useEffect, useRef, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { PageContainer, ProTable } from '@ant-design/pro-components'
import type { ActionType } from '@ant-design/pro-components'
import { Badge, Button, Popconfirm, Space, Table } from 'antd'

import { columnsAssignmentList } from 'components/assign.columns'
import { columnsSummaryItem } from 'components/summary-item.columns'
import { columnsSummaryPo } from 'components/summary-po-column.columns'
import { navigate } from 'gatsby'
import _ from 'lodash'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { AssignmentApi } from 'services/assignment-api'
import SummaryItem from 'summary.types'
import request from 'umi-request'

const Assignment = () => {
  const [userData] = useRecoilState(userAtom)
  const actionRef = useRef<ActionType>()
  const [assignmentList, setAssignmentList] = useState([])
  const [activeKey, setActiveKey] = useState<React.Key>('by_item')
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [selectedUserId, setSelectedUserId] = useState()

  const assignmentListMutation = useMutation(async (data: any) => {
    const api = useApi(AssignmentApi)
    return api.list(data)
  })

  const deleteMutation = useMutation(async (data: any) => {
    const api = useApi(AssignmentApi)
    return api.deleteAssignment(data)
  })

  useEffect(() => {
    actionRef.current?.clearSelected()
  }, [])

  useEffect(() => {
    if (!deleteMutation.isError && deleteMutation.data?.kind == 'ok') {
      actionRef.current?.clearSelected()
      actionRef.current?.reload()
    }
  }, [deleteMutation.isError, deleteMutation.data])

  const deleteAssignment = async () => {
    deleteMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        assignment_user_id: selectedUserId,
        list_inspection_id: selectedRows,
      },
    })
  }

  const request = async (params: any, sort: any, filter: any) => {
    const filters = Object.keys(params)
      .filter((e) => e == 'po_number' || e == 'username')
      .map((e) => {
        return {
          fields: e,
          value: params[e],
          type: 'string',
        }
      })

    const result = await assignmentListMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: [
          ...filters,
          {
            fields: 'assignment_status',
            value: '1',
            type: 'int',
          },
        ],
      },
    })
    if (result.kind == 'ok') {
      setAssignmentList(result.data)
    }
    return []
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumb: {
          routes: [
            {
              path: '',
              breadcrumbName: 'Assignment',
            },
          ],
        },
      }}
    >
      <ProTable<any>
        columns={columnsAssignmentList}
        actionRef={actionRef}
        scroll={{ x: 1000 }}
        request={request}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
          onChange(value) {
            console.log('value: ', value)
          },
        }}
        dataSource={assignmentList}
        rowKey="id"
        pagination={{
          pageSize: 5,
          onChange: (page) => console.log(page),
        }}
        dateFormatter="string"
        options={{
          density: true,
        }}
        rowSelection={{
          selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
          defaultSelectedRowKeys: [1],
          onSelect: (record, selected, selectedRows) => {
            const list_inspection_id = selectedRows.map((e) => {
              return e.id
            })
            setSelectedRows(list_inspection_id)
            const user_id = _.get(selectedRows, '[0].assignment_user_id')
            setSelectedUserId(user_id)
          },
        }}
        search={{
          labelWidth: 'auto',
        }}
        tableAlertOptionRender={() => {
          return (
            <Space size={16}>
              <a onClick={() => actionRef.current?.clearSelected()}>Clear</a>
              <Popconfirm
                title="Are you sure to delete this item?"
                onConfirm={deleteAssignment}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <a href="#" style={{ color: 'red' }}>
                  Delete
                </a>
              </Popconfirm>
            </Space>
          )
        }}
        toolbar={{}}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            type="primary"
            style={{
              backgroundColor: '#10c469',
              borderColor: '#10c469',
            }}
            onClick={() => {
              navigate('/assignment/add-assignment')
            }}
          >
            Add Assignment
          </Button>,
        ]}
      />
    </PageContainer>
  )
}

export default Assignment
