import * as React from 'react'

import { TableDropdown } from '@ant-design/pro-components'
import type { ProColumns } from '@ant-design/pro-components'
import { Button, Tag } from 'antd'

import { navigate } from 'gatsby'
import moment from 'moment'
import Flag from 'react-world-flags'
import SummaryItem from 'types/summary.types'

export const columnsSummaryItem = (currentPage: number, pageSize: number) => {
  const columns: ProColumns<SummaryItem>[] = [
    {
      dataIndex: 'index',
      valueType: 'index',
      width: 48,
      // fixed: "left",
      hideInSearch: true,
      render(dom, entity, index, action, schema) {
        return index + 1 + (currentPage - 1) * pageSize
      },
    },
    {
      title: 'Client',
      width: 120,
      dataIndex: 'client',
      // fixed: "left",
      // filters: true,
      // hideInSearch: false,
      render: (_, record) => {
        let str = record.client || ''
        const n = 20
        return str.length > n ? str.substring(0, n - 1) + '...' : str
      },
    },
    {
      title: 'Country',
      dataIndex: 'country_name',
      width: 150,
      // filters: true,
      hideInSearch: true,
      render: (_, record) => {
        const countries_iso = require('i18n-iso-countries')
        countries_iso.registerLocale(
          require('i18n-iso-countries/langs/en.json'),
        )
        const iso = countries_iso.getAlpha2Code(record.country_name, 'en')
        return (
          <>
            <Flag
              height={15}
              code={iso}
              style={{ border: '1px solid #eeeeee' }}
            />
            <span style={{ marginLeft: 10 }}>{record.country_name}</span>
          </>
        )
      },
    },
    {
      // disable: true,
      title: 'Vendor',
      dataIndex: 'vendor',
      filters: true,
      width: 120,
      render: (_, record) => {
        let str = record.vendor
        const n = 20
        return str.length > n ? str.substring(0, n - 1) + '...' : str
      },
    },
    {
      // disable: true,
      title: 'P.O No.',
      dataIndex: 'po_number',
      width: 120,
      // filters: true,
    },
    {
      disable: true,
      title: 'Item No. (Jan Code)',
      dataIndex: 'item_no',
      // filters: true,
      width: 120,
      hideInSearch: true,
    },
    {
      disable: true,
      title: 'Q.T.Y',
      dataIndex: 'order_qty',
      // width: 80,
      filters: true,
      hideInSearch: true,
    },
    {
      title: 'F.O.B',
      dataIndex: 'fob_usd',
      // width: 80,
      filters: true,
      hideInSearch: true,
      render: (_, record) => {
        return record.fob_usd + ' ' + record.fob_currency
      },
    },
    {
      disable: true,
      title: 'Total Amount',
      dataIndex: 'total_amount',
      // width: 120,
      filters: true,
      hideInSearch: true,
      render: (_, record) => {
        return record.total_amount + ' ' + record.fob_currency
      },
    },
    {
      title: 'ETD Date',
      key: 'etd_date',
      dataIndex: 'etd_date',
      valueType: 'dateTime',
      width: 120,
      // sorter: true,
      hideInSearch: true,
      render: (_, record) => {
        return record.loading_date
          ? moment(record.loading_date).format('ll')
          : '-'
      },
    },
    {
      title: 'Loading Date',
      key: 'loading_date',
      dataIndex: 'loading_date',
      valueType: 'dateTime',
      width: 120,
      // sorter: true,
      hideInSearch: true,
      render: (_, record) => {
        return record.loading_date
          ? moment(record.loading_date).format('ll')
          : '-'
      },
    },
    {
      title: 'Inspection Date Inline',
      key: 'inspection_date_inline',
      dataIndex: 'inspection_date_inline',
      valueType: 'dateTime',
      width: 120,
      // sorter: true,
      hideInSearch: true,
      render: (_, record) => {
        return record.inspection_date_inline
          ? moment(record.inspection_date_inline).format('ll')
          : '-'
      },
    },
    {
      title: 'Inspection Date Final',
      key: 'inspection_date_final',
      dataIndex: 'inspection_date_final',
      valueType: 'dateTime',
      width: 120,
      // sorter: true,
      hideInSearch: true,
      render: (_, record) => {
        return record.inspection_date_final
          ? moment(record.inspection_date_final).format('ll')
          : '-'
      },
    },
    {
      title: 'Inspection Date Re-Final',
      key: 'inspection_date_refinal',
      dataIndex: 'inspection_date_refinal',
      valueType: 'dateTime',
      width: 120,
      // sorter: true,
      hideInSearch: true,
      render: (_, record) => {
        return record.inspection_date_refinal
          ? moment(record.inspection_date_refinal).format('ll')
          : '-'
      },
    },
    {
      title: 'Result Fail/Pass',
      dataIndex: 'inspection_result',
      // hideInTable: true,
      width: 90,
      hideInSearch: true,
      render: (_, record) => {
        return (
          <Tag
            color={
              record.inspection_result == 'FAIL'
                ? 'red'
                : record.inspection_result == 'On Hold'
                ? 'orange'
                : record.inspection_result == 'PASS'
                ? 'green'
                : 'white'
            }
          >
            {record.inspection_result != 'PASS'
              ? record.inspection_result
              : record.report_status == 'INLINE'
              ? 'PASS-IN'
              : record.report_status == 'FINAL'
              ? 'PASS-FN'
              : 'PASS-RFN'}
          </Tag>
        )
      },
    },

    {
      title: 'Report',
      valueType: 'option',
      key: 'option',
      width: 100,
      fixed: 'right',
      hideInSearch: true,
      render: (_text, record, _, _action) => [
        <Button
          onClick={() => {
            navigate(
              '/aql-inspection/view?id=' + record.inspection_id + '#items',
            )
          }}
        >
          Report
        </Button>,
      ],
    },
  ]
  return columns
}
