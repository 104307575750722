import React, { useEffect, useRef, useState } from 'react'

import {
  DrawerForm,
  ProForm,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-components'
import { Alert } from 'antd'

import { CreateCustomerParams, UpdateCustomerParams } from 'customer/customer'
import { countryAtom } from 'models/master-data/master-data-store'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { CustomerApi } from 'services/customer-api'

const CustomerDrawer: React.FC<{
  title: string
  data: any
  children?: any
  visible?: boolean
  onClose?: any
}> = ({ title, data, children, visible = false, onClose }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const formRef = useRef<ProFormInstance>()
  const [userData, setUserData] = useRecoilState(userAtom)
  const [isEditMode, setIsEditMode] = useState(false)

  const [countryList] = useRecoilState(countryAtom)

  const updateMutation = useMutation(async (data: UpdateCustomerParams) => {
    const customerApi = useApi(CustomerApi)
    return customerApi.update(data)
  })

  const createMutation = useMutation(async (data: CreateCustomerParams) => {
    const customerApi = useApi(CustomerApi)
    return customerApi.create(data)
  })

  const update = async (newData: any) => {
    await updateMutation.mutateAsync({
      headers: {
        token: userData.token,
      },
      payload: newData,
    })
  }

  const create = async (newData: any) => {
    await createMutation.mutateAsync({
      headers: {
        token: userData.token,
      },
      payload: newData,
    })
  }

  useEffect(() => {}, [])

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setIsEditMode(true)
    } else {
      setIsEditMode(false)
    }
  }, [data])

  useEffect(() => {
    if (!createMutation.isLoading) {
      if (!createMutation.isError) {
        const result = createMutation.data
        if (result?.kind == 'ok') {
          onClose()
        }
      } else {
        setErrorMessage(createMutation.error.message)
        // console.log(createMutation.error.message)
      }
    }
  }, [createMutation.isLoading, createMutation.isError])

  useEffect(() => {
    if (!updateMutation.isLoading) {
      if (!updateMutation.isError) {
        const result = updateMutation.data
        if (result?.kind == 'ok') {
          onClose()
        }
      } else {
        setErrorMessage(updateMutation.error.message)
      }
    }
  }, [updateMutation.isLoading, updateMutation.isError])

  return (
    <DrawerForm
      title={title}
      formRef={formRef}
      trigger={children}
      autoFocusFirstInput
      visible={visible}
      drawerProps={{
        destroyOnClose: true,
        onClose: onClose,
      }}
      initialValues={data}
      // defaultValue={data}
      onFinish={async (values) => {
        // Update mode
        if (isEditMode) {
          console.log('Update', values)
          await update([{ ...values, customer_id: data.customer_id }])
        } else {
          console.log('Create', values)
          await create([values])
        }
      }}
    >
      <ProForm.Group>
        <ProFormText
          name="customer_name"
          width="md"
          label="Customer Name"
          placeholder=" Please enter a customer name"
          rules={[{ required: true }]}
        />
        <ProFormSelect
          options={countryList}
          width="md"
          name="country_id"
          label="Country"
          placeholder="Please enter a country"
          rules={[{ required: true }]}
        />

        <ProFormText
          width="md"
          name="phone"
          label="Phone"
          placeholder="Please enter a phone"
          rules={[{ required: false }]}
        />

        <ProFormText
          width="md"
          name="fax"
          label="Fax"
          placeholder="Please enter a fax"
          rules={[{ required: false }]}
        />
        <ProFormTextArea
          name="address"
          width="md"
          label="Address"
          fieldProps={{}}
          placeholder=" Please enter a address"
          rules={[{ required: false }]}
        />

        {createMutation.isError && (
          <Alert message={errorMessage} type="error" />
        )}
      </ProForm.Group>
    </DrawerForm>
  )
}

export default CustomerDrawer
