import React, { useRef, useState } from 'react'

import {
  ClearOutlined,
  DeleteOutlined,
  FileTextOutlined,
  ReloadOutlined,
  ShareAltOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { PageContainer, ProFormInstance } from '@ant-design/pro-components'
import {
  DrawerForm,
  ProCard,
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
  ProTable,
} from '@ant-design/pro-components'
import type { ActionType } from '@ant-design/pro-components'
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd'

import { columnShipment } from 'components/shipment-document.columns'
import { columnsSpecSheet } from 'components/spec-sheet.columns'
import { navigate } from 'gatsby'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { ShipmentApi } from 'services/shipment-api'
import { SpecSheetApi } from 'services/specsheet-api'
import SpecSheet from 'spec-sheet.types'

const PAGE_SIZE = 8

const ShipmentPage = () => {
  const [userData] = useRecoilState(userAtom)
  const actionRef = useRef<ActionType>()
  const trashActionRef = useRef<ActionType>()
  const [activeKey, setActiveKey] = useState<React.Key>('all')
  // const [selectList, setSelectList] = useState([])
  // const [disbleAdd, setDisableAdd] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [shipmentList, setShipmentList] = useState([])
  const [trashList, setTrashList] = useState([])
  const [selectedFiles, setSelectedFiles] = useState<any[]>([])

  const shipmentMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.shipmentList(data)
  })

  const trashListMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.trash(data)
  })

  const deleteFileMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.deleteFile(data)
  })

  const editSharMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.editShare(data)
  })

  const request = async (params = {}, _sort, _filter) => {
    console.log(params)
    // console.log(sort, filter)
    const filters = Object.keys(params)
      .filter((e) => e == 'po_number' || e == 'item_no')
      .map((e) => {
        return {
          fields: e,
          value: params[e],
          type: 'string',
        }
      })

    if (userData.role_id == 2) {
      filters.push({
        fields: 'customer_id',
        value: userData.customer_id,
        type: 'int',
      })
    } else if (userData.role_id == 9) {
      filters.push({
        fields: 'supplier_id',
        value: userData.supplier_id,
        type: 'int',
      })
    }

    const result = await shipmentMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: [
          ...filters,
          {
            fields: 'trash_status',
            value: 'A',
            type: 'string',
          },
        ],
      },
      params: {
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
    })

    if (result.kind == 'ok') {
      setShipmentList(result.data.data)
    }

    return result.data
  }

  const requestTrash = async (params = {}, _sort, _filter) => {
    console.log(params)
    // console.log(sort, filter)

    const result = await trashListMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },

      params: {},
    })

    if (result.kind == 'ok') {
      // setShipmentList(result.data)
      setTrashList(result.data)
    }

    return result.data
  }

  const restoreFiles = async () => {
    console.log(selectedFiles)
    if (!selectedFiles.length) {
      return
    }

    const result = await editSharMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        list_shipment_file_id: selectedFiles,
        status: 'A',
        option: 'edit_status',
      },
    })

    if (result.kind == 'ok') {
      trashActionRef.current?.reload()
      trashActionRef.current?.clearSelected()
    }
  }

  const deleteFiles = async () => {
    // console.log(selectedFiles)
    const result = await deleteFileMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        list_shipment_file_id: selectedFiles,
      },
    })

    if (result.kind == 'ok') {
      trashActionRef.current?.reload()
      trashActionRef.current?.clearSelected()
    }
  }

  let toolBar: any[] = []
  if (userData.role_name != 'owner' && userData.role_name != 'manager') {
    toolBar.push(
      <Button
        key="button"
        icon={<UploadOutlined />}
        type="primary"
        style={{
          backgroundColor: '#10c469',
          borderColor: '#10c469',
        }}
        onClick={() => {
          navigate('/shipment-document/upload')
        }}
      >
        Upload Files
      </Button>,
    )
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumb: {
          routes: [
            {
              path: '',
              breadcrumbName: 'Shipment Document',
            },
          ],
        },
      }}
      tabList={[
        {
          tab: (
            <>
              <FileTextOutlined />
              {'All Shipment Document'}
            </>
          ),
          key: 'all',
          closable: false,
        },
        {
          tab: (
            <>
              <DeleteOutlined />
              {'Trash'}
            </>
          ),
          key: 'trash',
          closable: false,
        },
      ]}
      tabProps={{
        onChange(activeKey) {
          // console.log(activeKey)
          setActiveKey(activeKey)
        },
        type: 'line',
      }}
    >
      <ProCard size="small">
        {activeKey == 'all' && (
          <ProCard ghost>
            <ProTable<SpecSheet>
              tableLayout="auto"
              columns={columnShipment}
              actionRef={actionRef}
              dataSource={shipmentList}
              request={request}
              // editable={{
              //   type: 'multiple',
              // }}
              // rowSelection={{
              //   type: 'checkbox',
              //   onChange: (key, value) => {
              //     if (value.length > 0) {
              //       setDisableAdd(true)
              //       setSelectList(value)
              //     } else {
              //       setDisableAdd(false)
              //     }
              //   },
              //   // onSelect: (value) => {
              //   //   list.push(value);
              //   // },
              // }}
              columnsState={{
                persistenceKey: 'pro-table-singe-demos',
                persistenceType: 'localStorage',
                onChange(value) {
                  console.log('value: ', value)
                },
              }}
              rowKey="item_no"
              pagination={{
                current: currentPage,
                pageSize: PAGE_SIZE,
                onChange: (page) => {
                  setCurrentPage(page)
                },
              }}
              dateFormatter="string"
              options={{
                density: true,
              }}
              search={{
                labelWidth: 'auto',
              }}
              toolBarRender={() => toolBar}
            />
            {/* {disbleAdd && (
              <div
                style={{
                  // height: 50,
                  // backgroundColor: "pink",
                  float: 'left',
                  marginLeft: 30,
                  marginBottom: 30,
                }}
              >
                <Button
                  key="assign"
                  type="primary"
                  size="large"
                  style={{ backgroundColor: '#10c469', borderColor: '#10c469' }}
                  onClick={() => console.log(selectList)}
                >
                  Add Selected Items
                </Button>
              </div>
            )} */}
          </ProCard>
        )}
        {activeKey == 'trash' && (
          <ProCard ghost>
            <ProTable<any>
              tableLayout="auto"
              columns={[
                {
                  title: 'Filename',
                  key: 'filename',
                  dataIndex: 'filename',
                },
                {
                  title: 'Sharing SKU',
                  key: 'list_share_item',
                  dataIndex: 'list_share_item',
                  render(dom, entity, index, action, schema) {
                    // console.log(entity.list_share_item)
                    const list = entity.list_share_item

                    return (
                      <div style={{ height: 80, overflow: 'scroll' }}>
                        {list.map((e: any) => (
                          <>
                            <ShareAltOutlined /> {e.item_no}
                            <br />
                            {'(P.O.: ' + e.po_number + ')'}
                            <br />
                          </>
                        ))}
                      </div>
                    )
                  },
                },
                {
                  title: 'Uploaded Date',
                  key: 'uploaded_date',
                  dataIndex: 'uploaded_date',
                },
              ]}
              actionRef={trashActionRef}
              dataSource={trashList}
              request={requestTrash}
              // editable={{
              //   type: 'multiple',
              // }}
              rowSelection={{
                // type: 'radio',
                onChange(selectedRowKeys, selectedRows, info) {
                  setSelectedFiles(selectedRowKeys)
                },
                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                alwaysShowAlert: true,
                // defaultSelectedRowKeys: [1],
              }}
              // columnsState={{
              //   persistenceKey: 'pro-table-singe-demos',
              //   persistenceType: 'localStorage',
              //   onChange(value) {
              //     console.log('value: ', value)
              //   },
              // }}
              tableAlertOptionRender={() => {
                return (
                  <Space>
                    <a
                      style={
                        selectedFiles.length == 0
                          ? {}
                          : {
                              color: 'green',
                            }
                      }
                      onClick={() => actionRef.current?.clearSelected()}
                      disabled={selectedFiles.length == 0}
                    >
                      <Space>
                        <ClearOutlined />
                        Clear
                      </Space>
                    </a>

                    <br />
                    <a
                      href="#"
                      onClick={restoreFiles}
                      disabled={selectedFiles.length == 0}
                    >
                      <Space>
                        <ReloadOutlined />
                        Restore
                      </Space>
                    </a>
                    <Popconfirm
                      title="Are you sure to delete these items?"
                      onConfirm={deleteFiles}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <a
                        href="#"
                        style={
                          selectedFiles.length == 0
                            ? {}
                            : {
                                color: 'red',
                              }
                        }
                        disabled={selectedFiles.length == 0}
                      >
                        <Space>
                          <DeleteOutlined />
                          Delete
                        </Space>
                      </a>
                    </Popconfirm>
                  </Space>
                )
              }}
              rowKey="shipment_file_id"
              pagination={{
                current: currentPage,
                pageSize: PAGE_SIZE,
                onChange: (page) => {
                  setCurrentPage(page)
                },
              }}
              dateFormatter="string"
              options={{
                density: true,
              }}
              search={false}
            />
          </ProCard>
        )}
      </ProCard>
    </PageContainer>
  )
}

export default ShipmentPage
