import React from 'react'

import ProLayout, { PageContainer } from '@ant-design/pro-layout'
import { ConfigProvider } from 'antd'
import enUSIntl from 'antd/lib/locale/en_US'

import AvatarDropdown from 'components/avatar-dropdown'

import SidebarMenu from './sidebar-menu'

const PrimaryLayout: React.FC<{
  location: any
  pageContext: { layout: string }
  children: any
}> = ({ children, location }) => {
  const breadCrumb = location.pathname
    .split('/')
    .filter((e: any) => e)
    .map((e: any) => ({
      path: '/' + e,
      breadcrumbName: e,
    }))

  const pageKey = location.pathname
    .split('/')
    .filter((e: string) => e)
    .join('')

  return (
    <ConfigProvider locale={enUSIntl}>
      <ProLayout
        logo={<img src="/images/logo.png" />}
        title="SIAM KARAT"
        // pageTitleRender={(props) => {
        //   console.log(props)
        //   return ''
        // }}
        navTheme="light"
        headerTheme="dark"
        rightContentRender={() => (
          <>
            <AvatarDropdown />
          </>
        )}
        layout="mix"
        fixSiderbar={true}
        fixedHeader={true}
        menuContentRender={() => {
          return <SidebarMenu location={location} />
        }}
      >
        {children}
      </ProLayout>
    </ConfigProvider>
  )
}

export default PrimaryLayout
