export const reportType = {
  INLINE: {
    text: 'INLINE',
  },
  FINAL: {
    text: 'FINAL',
  },
  ['RE-FINAL' as string]: {
    text: 'RE-FINAL',
  },
  ['RE-FINAL2' as string]: {
    text: 'RE-FINAL2',
  },
  ['RE-FINAL3' as string]: {
    text: 'RE-FINAL3',
  },
}
