import React, { useEffect } from 'react'

import { navigate } from 'gatsby'
import { userAtom } from 'models/user/user-store'
import { useRecoilState } from 'recoil'

const IndexPage = () => {
  const [userData, setUserData] = useRecoilState(userAtom)

  useEffect(() => {
    if (userData.token) {
      navigate('/summary-of-order')
    } else {
      navigate('/auth/login')
    }
  }, [])

  return <></>
}

export default IndexPage
