import { ProSchemaValueEnumObj } from '@ant-design/pro-components'

export const activeStatus = {
  ['FAIL' as string]: {
    text: 'FAIL',
    color: 'red',
  },
  ['PASS' as string]: {
    text: 'PASS',
    color: 'green',
  },
  ['On Hold' as string]: {
    text: 'On Hold',
    color: 'orange',
  },
}
