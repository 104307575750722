import React, { useEffect, useState } from 'react'

import {
  BarChartOutlined,
  CloudUploadOutlined,
  DatabaseOutlined,
  OrderedListOutlined,
  PlusOutlined,
  SettingOutlined,
  SnippetsOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Menu } from 'antd'

import { navigate } from 'gatsby'
import { menuAtom, userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { UserApi } from 'services/user-api'
import { DiskSizeParam } from 'user/menu'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key: key == 'dashboard' ? 'summary-of-order' : key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const iconSize = 20
const IconMap: any = {
  dashboard: <BarChartOutlined style={{ fontSize: iconSize }} />,
  'aql-inspection': <OrderedListOutlined style={{ fontSize: iconSize }} />,
  plus: <PlusOutlined style={{ fontSize: iconSize }} />,
  assignment: <UserSwitchOutlined style={{ fontSize: iconSize }} />,
  'summary-of-order': <SnippetsOutlined style={{ fontSize: iconSize }} />,
  'monthly-summary': <SnippetsOutlined style={{ fontSize: iconSize }} />,
  'spec-sheet': <CloudUploadOutlined style={{ fontSize: iconSize }} />,
  'shipment-document': <CloudUploadOutlined style={{ fontSize: iconSize }} />,
  'user-management': <UsergroupAddOutlined style={{ fontSize: iconSize }} />,
  setting: <SettingOutlined style={{ fontSize: iconSize }} />,
  database: <DatabaseOutlined style={{ fontSize: iconSize }} />,
}

const SidebarMenu: React.FC<{
  location: any
}> = ({ location }) => {
  const [userData] = useRecoilState(userAtom)
  const [menuData] = useRecoilState(menuAtom)
  const [menuItems, setMenuItems] = useState<MenuProps['items']>([])
  const [diskSize, setDiskSize] = useState<string>('')

  const diskSizeMutation = useMutation(async (data: DiskSizeParam) => {
    const userApi = useApi(UserApi)
    return userApi.getDiskSize(data)
  })

  // ANCHOR Get disk size
  useEffect(() => {
    if (userData.user_id) {
      diskSizeMutation.mutate({ token: userData.token })
    }
  }, [])

  useEffect(() => {
    if (!diskSizeMutation.isError) {
      const diskSizeResult = diskSizeMutation.data
      if (diskSizeResult?.kind == 'ok') {
        setDiskSize(diskSizeResult?.data?.free)
      }
    }
  }, [diskSizeMutation.data, diskSizeMutation.isError])

  // ANCHOR Generate menu items
  useEffect(() => {
    const dividerIndex = menuData.findIndex(
      (e: any) => e.path == 'user-management' || e.path == 'setting',
    )

    let md = menuData
      .filter((e: any) => e.path != 'summary-of-order')
      .map((e: any) => {
        return getItem(e.menu_name, e.path, IconMap[e.path])
      })
    if (dividerIndex != -1) {
      md.splice(dividerIndex, 0, { type: 'divider' })
    }
    if (diskSize) {
      md.push({ type: 'divider' })
      md.push({
        key: 'available',
        icon: IconMap['database'],
        children: null,
        label: 'Free: ' + diskSize,
        disabled: true,
      })
    }

    setMenuItems(md)
  }, [menuData, diskSize])

  const onClick: MenuProps['onClick'] = (e) => {
    navigate('/' + e.key)
  }

  return (
    <Menu
      onClick={onClick}
      defaultSelectedKeys={[
        location?.pathname
          .split('/')
          .filter((e: string) => e)
          .join(''),
      ]}
      defaultOpenKeys={['summary-of-order']}
      mode="inline"
      items={menuItems}
    />
  )
}

export default SidebarMenu
