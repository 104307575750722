import React from 'react'

const WhiteLayout: React.FC<{
  location: any
  pageContext: { layout: string }
  custom404: any
  children: any
}> = ({ children }) => {
  return <>{children}</>
}

export default WhiteLayout
