import React, { useRef, useState } from 'react'

import {
  CheckOutlined,
  ClearOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { ActionType, ProCard, ProTable } from '@ant-design/pro-components'
import { PageContainer } from '@ant-design/pro-layout'
import { Alert, Button, Space, Table, Typography, message } from 'antd'

import SKBreadcrumb from 'components/sk-breadcumb'
import { FilePondHookProps } from 'filepond'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import { userAtom } from 'models/user/user-store'
import { FilePond, FilePondProps, registerPlugin } from 'react-filepond'
import { useMutation } from 'react-query'
import Flag from 'react-world-flags'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { ShipmentApi } from 'services/shipment-api'
import { SpecSheetApi } from 'services/specsheet-api'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
)

interface ShipmentUploadPageProps {}

const ShipmentUploadPage: React.FC<ShipmentUploadPageProps> = () => {
  const [userData] = useRecoilState(userAtom)
  const [currentShipmentItems, setCurrentShipmentItems] = useState<any>()
  const shipmentAction = useRef<ActionType>()
  const [files, setFiles] = useState<any[]>([])
  const filepondRef = useRef<FilePond>()
  const [selectedItems, setSelectedItems] = useState<any[]>([])

  const shipmentAddShareMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.shipmentAddShareList(data)
  })

  const requestShipmentAddShareList = async (
    params: any = {},
    _sort = {},
    _filter = {},
  ) => {
    console.log(params)
    // console.log(sort, filter)
    const filters = Object.keys(params)
      .filter((e) => e == 'po_number' || e == 'item_no')
      .map((e) => {
        return {
          fields: e,
          value: params[e],
          type: 'string',
        }
      })

    const result = await shipmentAddShareMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: [...filters],
      },
      params: {
        limit: 1000,
        offset: 0,
        // specsheet_file_id: currentFileId,
      },
    })

    if (result.kind == 'ok') {
      // console.log(result.data)
      setCurrentShipmentItems(result.data)
    }

    return [] //result.data
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumbRender(props, defaultDom) {
          return <SKBreadcrumb routes={props?.breadcrumb?.routes || []} />
        },
        breadcrumb: {
          routes: [
            {
              path: '/shipment-document',
              breadcrumbName: 'Shipment Document',
            },
            {
              path: '',
              breadcrumbName: 'Upload',
            },
          ],
        },
      }}
    >
      <ProCard
        split="vertical"
        title="Upload File"
        headerBordered
        extra={
          <Button
            type="primary"
            // size="small"
            onClick={() => {
              if (selectedItems.length > 0 && files.length > 0) {
                filepondRef.current?.processFiles()
              } else {
                message.error(
                  'Please select at least one of each item and file.',
                )
              }
            }}
          >
            <UploadOutlined />
            Upload and save
          </Button>
        }
      >
        <ProCard size="small" colSpan={16}>
          <ProTable<any>
            headerTitle="Search Items to share"
            actionRef={shipmentAction}
            // ANCHOR Columns
            columns={[
              // {
              //   title: '#',
              //   key: 'index',
              //   valueType: 'index',
              // },
              {
                title: 'SKU',
                key: 'item_no',
                dataIndex: 'item_no',
              },
              {
                title: 'P.O. No.',
                key: 'po_number',
                dataIndex: 'po_number',
              },
              {
                title: 'Country',
                key: 'country_name',
                dataIndex: 'country_name',
                search: false,

                render: (_, record) => {
                  const countries_iso = require('i18n-iso-countries')
                  countries_iso.registerLocale(
                    require('i18n-iso-countries/langs/en.json'),
                  )
                  const iso = countries_iso.getAlpha2Code(
                    record.country_name,
                    'en',
                  )
                  return (
                    <>
                      <Flag
                        height={15}
                        code={iso}
                        style={{ border: '1px solid #eeeeee' }}
                      />
                      <span style={{ marginLeft: 10 }}>
                        {record.country_name}
                      </span>
                    </>
                  )
                },
              },
              {
                title: 'Vendor',
                key: 'supplier_name',
                dataIndex: 'supplier_name',
                search: false,
                render: (_, record) => {
                  let str = record.supplier_name
                  const n = 12
                  return (
                    <span title={str}>
                      {str.length > n ? str.substring(0, n - 1) + '...' : str}
                    </span>
                  )
                },
              },
            ]}
            rowSelection={{
              // type: 'radio',
              onChange(selectedRowKeys, selectedRows, info) {
                setSelectedItems(selectedRows)
              },
              alwaysShowAlert: true,
              selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
              // defaultSelectedRowKeys: [1],
            }}
            request={requestShipmentAddShareList}
            rowKey="item_no"
            options={false}
            pagination={{ pageSize: 7 }}
            search={{
              filterType: 'light',
            }}
            tableAlertOptionRender={() => {
              return (
                <a
                  style={{
                    color: 'green',
                  }}
                  onClick={() => shipmentAction.current?.clearSelected()}
                  disabled={selectedItems.length == 0}
                >
                  <Space>
                    <ClearOutlined />
                    Clear
                  </Space>
                </a>
              )
            }}
            // search={false}
            dataSource={currentShipmentItems}
          />
        </ProCard>
        <ProCard>
          {/* <div
            style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}
          > */}
          <div style={{ marginBottom: 20, marginTop: 7 }}>
            <span style={{ fontSize: 16 }}>Select files to upload</span>
          </div>
          <Alert
            message={`Selected ${files.length} File`}
            style={{ width: '100%', marginBottom: 15, padding: '12px 24px' }}
            action={
              <a
                style={{
                  color: 'green',
                }}
                onClick={() => filepondRef.current?.removeFiles()}
              >
                <Space>
                  <ClearOutlined />
                  Clear
                </Space>
              </a>
            }
          />
          {/* </div> */}
          <FilePond
            ref={filepondRef}
            files={files}
            // onupdatefiles={setFiles}
            allowMultiple={true}
            // acceptedFileTypes={['mp4']}
            maxFiles={10}
            imagePreviewMarkupShow={false}
            onaddfile={(error, file) => {
              // console.log(file)
              setFiles((previousFiles) => [...previousFiles, file])
            }}
            onremovefile={(error, file) => {
              // console.log(file)
              setFiles((previousFiles) => {
                let fls = previousFiles.filter((e) => e.id != file.id)
                return fls
              })
            }}
            onprocessfiles={() => {
              message.success('Upload success')
              filepondRef.current?.removeFiles()
              shipmentAction.current?.clearSelected()
            }}
            onprocessfile={(error, file) => {
              if (!error) {
              }
            }}
            instantUpload={false}
            server={{
              url: process.env.API_URL + 'upload',
              // headers: {
              //   'x-access-token': userData.token,
              // },
              process: (
                fieldName,
                file,
                metadata,
                load,
                error,
                progress,
                abort,
                transfer,
                options,
              ) => {
                // fieldName is the name of the input field
                // file is the actual file object to send
                const formData = new FormData()
                formData.append(fieldName, file, file.name)
                // formData.append('type_upload', mediaGroup as string)
                // let inspection_id = selectedItems
                //   .map((e) => e.inspection_id)
                //   .join(',')
                // formData.append('inspection_id', inspection_id)
                let inspection_item_id = selectedItems
                  .map((e) => e.item_id)
                  .join(',')
                formData.append('inspection_item_id', inspection_item_id)

                const request = new XMLHttpRequest()
                request.open('POST', process.env.API_URL + 'shipment')
                request.setRequestHeader('x-access-token', userData.token)

                // Should call the progress method to update the progress to 100% before calling load
                // Setting computable to false switches the loading indicator to infinite mode
                request.upload.onprogress = (e) => {
                  progress(e.lengthComputable, e.loaded, e.total)
                }

                // Should call the load method when done and pass the returned server file id
                // this server file id is then used later on when reverting or restoring a file
                // so your server knows which file to return without exposing that info to the client
                request.onload = function () {
                  if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    load(request.responseText)
                  } else {
                    // Can call the error method if something is wrong, should exit after
                    error('oh no')
                  }
                }

                request.send(formData)

                // Should expose an abort method so the request can be cancelled
                return {
                  abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort()

                    // Let FilePond know the request has been cancelled
                    abort()
                  },
                }
              },
            }}
            name="files[]"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
        </ProCard>
      </ProCard>
    </PageContainer>
  )
}

export default ShipmentUploadPage
