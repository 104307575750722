import * as React from 'react'

import type { ProColumns } from '@ant-design/pro-components'
import { Tag } from 'antd'

import AssignPO from '../types/assign-po.types'

export const columnsPo: ProColumns<AssignPO>[] = [
  {
    title: 'P.O No.',
    key: 'po_number',
    dataIndex: 'po_number',
  },
  {
    title: 'Country',
    key: 'country',
    dataIndex: 'country',
    search: false,
  },
  {
    title: 'Client',
    key: 'customer_name',
    dataIndex: 'customer_name',
    search: false,
    width: 150,
    render: (_, record) => {
      let str = record.customer_name
      const n = 18
      return str.length > n ? str.substring(0, n - 1) + '...' : str
    },
  },
  {
    title: 'Shipper',
    key: 'shipper',
    dataIndex: 'shipper',
    search: false,
    render: (_, record) => {
      let str = record.shipper
      const n = 18
      return str.length > n ? str.substring(0, n - 1) + '...' : str
    },
  },
  {
    title: 'Maker',
    key: 'maker',
    dataIndex: 'maker',
    search: false,
    render: (_, record) => {
      let str = record.maker
      const n = 18
      return str.length > n ? str.substring(0, n - 1) + '...' : str
    },
  },
  {
    title: 'Inspection Result',
    key: 'active_status_name',
    dataIndex: 'active_status_name',
    search: false,
    render: (_, record) => {
      return (
        <Tag
          color={
            record.active_status_name == 'FAIL'
              ? 'red'
              : record.active_status_name == 'On Hold'
              ? 'orange'
              : 'green'
          }
        >
          {record.active_status_name != 'PASS'
            ? record.active_status_name
            : record.report_status_name == 'INLINE'
            ? 'PASS-IN'
            : record.report_status_name == 'FINAL'
            ? 'PASS-FN'
            : 'PASS-RFN'}
        </Tag>
      )
    },
  },
]
