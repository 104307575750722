import React, { useEffect, useRef, useState } from 'react'

import { DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import { PageContainer, ProCard, ProTable } from '@ant-design/pro-components'
import type { ActionType } from '@ant-design/pro-components'
import {
  Badge,
  Button,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Menu,
  Table,
} from 'antd'
import { Col, Row, Statistic } from 'antd'
import enUSIntl from 'antd/lib/locale/en_US'

import MonthlyClientChart from 'components/monthly_client.chart'
import { columnsMonthlyClient } from 'components/monthly_client.column'
import { columnsMonthlyVendor } from 'components/monthly_vendor.column'
import { userAtom } from 'models/user/user-store'
import moment from 'moment'
import { Monthly } from 'monthly.types'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { SummaryApi } from 'services/summary-order-api'
import * as XLSX from 'xlsx'

const PAGE_SIZE = 5

const MonthlySummaryPage = () => {
  const actionRef = useRef<ActionType>()
  const [userData] = useRecoilState(userAtom)
  const [activeKey, setActiveKey] = useState<React.Key>('vendor')
  const [currentPage, setCurrentPage] = useState(1)
  const [items, setItems] = useState<any>([])
  const [totals, setTotals] = useState<any>({})
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const summaryOfMonthlyMutation = useMutation(async (data: any) => {
    const api = useApi(SummaryApi)
    return api.summaryOfMonthly(data)
  })

  useEffect(() => {
    request()
  }, [activeKey])

  const request = async (params = {}, _sort, _filter) => {
    const result = await summaryOfMonthlyMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: [
          // {
          //   fields: 'vendor_id',
          //   value: 506,
          //   type: 'int',
          // },
        ],
      },
      params: {
        option: activeKey,
        year: selectedYear,
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: 20, //PAGE_SIZE,
      },
    })

    if (result.kind == 'ok') {
      // console.log(result.data)
      setItems(result.data?.result || [])
      setTotals(result.data?.total || {})
    }

    return {
      data: result.data?.result || [],
      success: true,
      total: result.data?.result.length || 0,
    }
  }

  const exportToExcel = async () => {
    const result = await summaryOfMonthlyMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: [],
      },
      params: {
        option: activeKey,
        year: selectedYear,
      },
    })

    if (result.kind == 'ok') {
      let data: any = result.data?.result || []
      // const columns = columnsSummaryItem(currentPage, PAGE_SIZE)
      // console.log(columns)
      const month: any = {
        '1': 'Jan',
        '2': 'Feb',
        '3': 'Mar',
        '4': 'Apr',
        '5': 'May',
        '6': 'Jun',
        '7': 'Jul',
        '8': 'Aug',
        '9': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec',
      }

      data = data.map((e: any) => {
        let total = 0
        for (const m in month) {
          if (Object.prototype.hasOwnProperty.call(month, m)) {
            const mshort = month[m]
            if (e.summary[m].length) {
              const amount = e.summary[m][0]['total_amount']
              e[mshort] = amount //.toFixed(2)
              total += amount
            } else {
              e[mshort] = ''
            }
          }
        }
        e['Total'] = total //.toFixed(2)
        delete e['summary']
        return e
      })

      let monthly_total: any = {}
      if (activeKey == 'vendor') {
        monthly_total = {
          vendor_id: '',
          vendor_name: 'TOTAL',
        }
      } else {
        monthly_total = {
          client_id: '',
          client_name: 'TOTAL',
        }
      }
      let total: any = result.data?.total || {}
      let sum = 0
      for (const m in month) {
        if (Object.prototype.hasOwnProperty.call(month, m)) {
          const mshort = month[m]
          const amount = total[m]['total_amount']
          monthly_total[mshort] = amount //.toFixed(2)
          sum += amount
        }
      }
      monthly_total['Total'] = sum //.toFixed(2)

      data = [...data, monthly_total]

      // return result.data || {}
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      var range = { s: { r: 2, c: 3 }, e: { r: data.length + 1, c: 15 } }
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          let cell = ws[XLSX.utils.encode_cell({ r: R, c: C })]
          if (!cell || cell.t != 'n') continue // only format numeric cells
          cell.z = '#,##0.00'
        }
      }
      XLSX.utils.book_append_sheet(wb, ws, activeKey.toString())

      // สร้างไฟล์ excel และดาวน์โหลด
      XLSX.writeFile(wb, `monthly-summary(by-${activeKey}).xlsx`)
    }
  }

  let toolBar: any[] = [
    <DatePicker
      value={moment(`${selectedYear}-05-05`)}
      onChange={(value) => {
        setSelectedYear(value?.format('YYYY') || '2022')
        actionRef.current?.reload()
      }}
      picker="year"
    />,
  ]
  if (userData.role_name != 'owner') {
    toolBar.push(
      <Button
        key="button"
        icon={<DownloadOutlined />}
        type="primary"
        style={{
          backgroundColor: '#10c469',
          borderColor: '#10c469',
        }}
        onClick={() => {
          exportToExcel()
        }}
      >
        Export
      </Button>,
    )
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumb: {
          routes: [
            {
              path: '',
              breadcrumbName: 'Monthly Summary',
            },
          ],
        },
      }}
    >
      {/* <ProCard
        direction="row"
        bordered
        style={{ marginBottom: 16, padding: 0 }}
      > */}
      {/* <ProCard colSpan={18} ghost style={{ height: 180 }}>
          <MonthlyClientChart />
        </ProCard>
        <ProCard colSpan={6}>
          <Row gutter={12}>
            <Col span={12}>
              <Statistic
                title={activeKey == 'vendor' ? 'Vendor' : 'Client'}
                value={1128}
                prefix=""
              />
            </Col>
            <Col span={12}>
              <Statistic
                title="Year"
                value={selectedYear}
                groupSeparator=""
                suffix=""
              />
            </Col>
          </Row>
        </ProCard> */}
      {/* </ProCard> */}
      <ProTable<Monthly>
        columns={
          activeKey == 'vendor' ? columnsMonthlyVendor : columnsMonthlyClient
        }
        actionRef={actionRef}
        scroll={{ x: 1500 }}
        request={request}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
          onChange(value) {
            console.log('value: ', value)
          },
        }}
        rowKey="id"
        pagination={{
          pageSize: 5,
          onChange: (page) => console.log(page),
        }}
        dateFormatter="string"
        options={{
          density: true,
        }}
        search={{
          labelWidth: 'auto',
        }}
        dataSource={items}
        toolbar={{
          menu: {
            type: 'tab',
            activeKey: activeKey,
            items: [
              {
                key: 'vendor',
                label: <span>By Vendor</span>,
              },
              {
                key: 'client',
                label: <span>By Client</span>,
              },
            ],
            onChange: (key) => {
              setActiveKey(key as string)
            },
          },
        }}
        toolBarRender={() => toolBar}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[1].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[2].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[3].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[4].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[5].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[6].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[7].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[8].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[9].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[10].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={11}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[11].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={12}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? totals[12].total_amount.toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={13}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {totals && Object.keys(totals).length
                    ? Object.keys(totals)
                        .reduce(
                          (a: any, c: any) =>
                            (a = a + parseFloat(totals[c].total_amount)),
                          0,
                        )
                        .toLocaleString()
                    : 0}
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </PageContainer>
  )
}

export default MonthlySummaryPage
