import * as React from 'react'

import type { ProColumns } from '@ant-design/pro-components'
import { Tag } from 'antd'

import Flag from 'react-world-flags'

import AssignItem from '../types/assign.types'

export const columnsAssignment: ProColumns<AssignItem>[] = [
  {
    title: 'Username',
    key: 'username',
    dataIndex: 'username',
  },
  {
    title: 'Name',
    key: 'profile_name',
    dataIndex: 'profile_name',
    // search: false,
  },
  {
    title: 'Country',
    key: 'country_name',
    dataIndex: 'country_name',
    search: false,
  },
]

export const columnsAssignmentList: ProColumns<any>[] = [
  {
    title: 'P.O.',
    key: 'po_number',
    dataIndex: 'po_number',
  },
  {
    title: 'Username',
    key: 'username',
    dataIndex: 'username',
    render(dom, entity, index, action, schema) {
      return <Tag color="blue">{dom}</Tag>
    },
  },
  {
    title: 'Country',
    key: 'country',
    dataIndex: 'country',
    search: false,
    render: (_, record) => {
      const countries_iso = require('i18n-iso-countries')
      countries_iso.registerLocale(require('i18n-iso-countries/langs/en.json'))
      const iso = countries_iso.getAlpha2Code(record.country, 'en')
      return (
        <>
          <Flag
            height={15}
            code={iso}
            style={{ border: '1px solid #eeeeee' }}
          />
          <span style={{ marginLeft: 10 }}>{record.country}</span>
        </>
      )
    },
  },
  {
    title: 'Client',
    key: 'customer_name',
    dataIndex: 'customer_name',
    search: false,
  },
  {
    title: 'Shipper',
    key: 'shipper',
    dataIndex: 'shipper',
    search: false,
  },
  {
    title: 'Maker',
    key: 'maker',
    dataIndex: 'maker',
    search: false,
  },
  {
    title: 'Inspection Result',
    key: 'active_status_name',
    dataIndex: 'active_status_name',
    search: false,
    width: 90,
    render: (_, record) => {
      return (
        <Tag
          color={
            record.active_status_name == 'FAIL'
              ? 'red'
              : record.active_status_name == 'On Hold'
              ? 'orange'
              : 'green'
          }
        >
          {record.active_status_name != 'PASS'
            ? record.active_status_name
            : record.report_status_name == 'INLINE'
            ? 'PASS-IN'
            : record.report_status_name == 'FINAL'
            ? 'PASS-FN'
            : 'PASS-RFN'}
        </Tag>
      )
    },
  },
]
