import React, { useEffect, useState } from 'react'

import {
  PageContainer,
  ProCard,
  ProDescriptions,
} from '@ant-design/pro-components'
import { Badge, Radio, Space, Tabs, TabsProps } from 'antd'

import DraggableMedia from 'components/dragable-media'
import { Link } from 'gatsby'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { AqlApi } from 'services/aql-api'
import { MasterDataApi } from 'services/master-data-api'

import FileEditor from './file-editor'
import FileUploader from './file-uploader'

interface ItemReportImageProps {}

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => {
  console.log(props)
  return <DefaultTabBar {...props}>{(node) => node}</DefaultTabBar>
}

const ItemReportVideo: React.FC<ItemReportImageProps> = () => {
  const [userData] = useRecoilState(userAtom)
  const [tabList, setTabList] = useState([])
  const [videoList, setVideoList] = useState<any>({})
  const [currentItemData, setCurrentItemData] = useState<any>({})
  const [currentSelectItem, setCurrentSelectItem] = useState<any>()
  const [activeKey, setActiveKey] = useState('1')
  const [previewActiveKey, setPreviewActiveKey] = useState('1')
  const [inspectionId, setInspectionId] = useState()
  const [actionList, setActionList] = useState<string[]>([])

  /**
   * ANCHOR Mutation
   */

  const itemMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.viewItem(data)
  })

  const tabTypeMutation = useMutation(async (data: any) => {
    const api = useApi(MasterDataApi)
    return api.dropdown(data)
  })

  const videoListMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.ItemListVideo(data)
  })

  /**
   * ANCHOR useEffect
   */
  useEffect(() => {
    let al = userData.menu_action?.filter(
      (e: any) => e.path == 'aql-inspection',
    )
    al = al.length ? al[0].action_list : []
    console.log(al)
    setActionList(al)

    const pageParams = new URLSearchParams(location.search)
    const inspection_id = pageParams.get('inspection_id')
    const item_id = pageParams.get('item_id')

    setInspectionId(inspection_id)

    itemMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        inspection_id,
        item_id,
      },
    })

    tabTypeMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        option: 'video_type',
      },
    })

    reloadMediaList()
  }, [])

  useEffect(() => {
    if (!tabTypeMutation.isError && tabTypeMutation.data) {
      if (tabTypeMutation.data.kind == 'ok') {
        setTabList(tabTypeMutation.data.data.result)
      }
    }
  }, [tabTypeMutation.data, tabTypeMutation.isError])

  useEffect(() => {
    if (!itemMutation.isError && itemMutation.data) {
      if (
        itemMutation.data.kind == 'ok' &&
        itemMutation.data.data.result.length
      ) {
        const pageParams = new URLSearchParams(location.search)

        const report_status = pageParams.get('report_status')
        let data = itemMutation.data.data.result[0]
        data.type = report_status?.toUpperCase()
        setCurrentItemData(data)
      }
    }
  }, [itemMutation.data, itemMutation.isError])

  useEffect(() => {
    if (!videoListMutation.isError && videoListMutation.data) {
      console.log(videoListMutation.data)
      if (videoListMutation.data.kind == 'ok') {
        let result = videoListMutation.data.data.result
        console.log(result)
        if (result.list_item?.length) {
          console.log(result.list_item[0])
          setVideoList(result.list_item[0])
        } else {
          setVideoList({})
        }
      }
    }
  }, [videoListMutation.data, videoListMutation.isError])

  useEffect(() => {
    // console.log(videoList[0])
  }, [videoList])

  /**
   * ANCHOR Functions
   */

  const onSelectItem = (item: any) => {
    // console.log('onselectitem', item)
    setCurrentSelectItem(item)
    setPreviewActiveKey('1')
  }

  const reloadMediaList = () => {
    const pageParams = new URLSearchParams(location.search)
    const inspection_id = pageParams.get('inspection_id')
    let item_id: any = pageParams.get('item_id')
    item_id = parseInt(item_id)
    const report_status = pageParams.get('report_status')
    const rs = { inline: 1, final: 2, refinal: 3, refinal2: 4, refinal3: 5 }

    if (inspection_id && !isNaN(parseInt(inspection_id))) {
      videoListMutation.mutate({
        headers: {
          'x-access-token': userData.token,
        },
        params: {
          inspection_id: inspection_id,
          report_status: rs[report_status],
        },
        body: {
          list_item_id: [item_id],
        },
      })
    }
  }

  const onUpdateSuccess = () => {
    console.log('update')
    reloadMediaList()
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumb: {
          itemRender(route, params, routes, paths) {
            if (route.path) {
              return <Link to={route.path}>{route.breadcrumbName}</Link>
            } else {
              return route.breadcrumbName
            }
          },
          routes: [
            {
              path: '/aql-inspection',
              breadcrumbName: 'AQL Inspection',
            },
            {
              path: '/aql-inspection/view?id=' + inspectionId,
              breadcrumbName: 'Inspection Detail',
            },
            {
              path: '',
              breadcrumbName: 'Videos',
            },
          ],
        },
      }}
    >
      <ProCard size="small">
        <ProDescriptions
          // actionRef={actionRef}
          // bordered
          labelStyle={{ color: '#188ae2' }}
          formProps={{
            onValuesChange: (e, f) => console.log(f),
          }}
          title="Item Details"
          // request={async () => {

          // }}
          dataSource={currentItemData}
          columns={[
            {
              title: 'P.O. No.',
              key: 'po_number',
              dataIndex: 'po_number',
            },
            {
              title: 'Item No.',
              key: 'item_no',
              dataIndex: 'item_no',
            },
            {
              title: 'Color',
              key: 'color',
              dataIndex: 'color',
            },
            {
              title: 'Description',
              key: 'item_description',
              dataIndex: 'item_description',
            },
            {
              title: 'Supplier',
              key: 'supplier',
              dataIndex: 'supplier_name',
            },
            {
              title: 'Type',
              key: 'type',
              dataIndex: 'type',
            },
          ]}
        ></ProDescriptions>
      </ProCard>
      <ProCard layout="default" style={{ marginTop: 20 }} size={'small'}>
        <Tabs
          type="card"
          tabPosition={'left'}
          tabBarStyle={{ width: 180 }}
          size={'small'}
          onTabClick={(activeKey) => {
            setCurrentSelectItem(null)
            setActiveKey(activeKey)
          }}
          // renderTabBar={() => <>abc</>}
        >
          {tabList.map((e: any) => {
            return (
              <Tabs.TabPane
                tab={
                  <Space>
                    <Badge
                      count={
                        Object.keys(videoList).length
                          ? videoList['' + e.id].list_video.length
                          : 0
                      }
                      // showZero
                      color="#52c41a"
                      style={{ marginTop: -5 }}
                    >
                      <></>
                    </Badge>
                    <span style={{ marginLeft: 10 }}>
                      {e.value.length > 14
                        ? e.value.substring(0, 14) + '...'
                        : e.value}
                    </span>
                  </Space>
                }
                key={e.id}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: 5, flex: 1 }}>
                    <div style={{ padding: 5, margin: '5px, 0' }}>
                      {e.value}
                    </div>
                    <DraggableMedia
                      mediaType="video"
                      items={
                        Object.keys(videoList).length
                          ? videoList['' + e.id].list_video
                          : []
                      }
                      onSelectItem={onSelectItem}
                    />
                  </div>
                  {actionList.includes('edit') ? (
                    <div
                      style={{
                        display: 'flex',
                        // flex: 1,
                        width: 350,
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        borderLeft: '1px solid #eaeaea',
                        padding: 5,
                        paddingLeft: 10,
                        // backgroundColor: 'red',
                      }}
                    >
                      <Tabs
                        defaultActiveKey="1"
                        onChange={() => {}}
                        type="card"
                        style={{ width: '100%' }}
                        activeKey={previewActiveKey}
                        onTabClick={(pActiveKey) => {
                          setPreviewActiveKey(pActiveKey)
                        }}
                      >
                        <Tabs.TabPane tab="Preview & Edit" key="1">
                          <FileEditor
                            mediaType="video"
                            item={currentSelectItem}
                            onUpdateSuccess={onUpdateSuccess}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Upload" key="2">
                          <FileUploader
                            mediaType="video"
                            mediaGroup={activeKey}
                            onSuccess={onUpdateSuccess}
                          />
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  ) : null}
                </div>
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </ProCard>
    </PageContainer>
  )
}

export default ItemReportVideo
