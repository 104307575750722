import React, { useEffect, useState } from 'react'

import { ProCard } from '@ant-design/pro-components'
import { Tabs } from 'antd'

import DraggableMedia from 'components/dragable-media'

import FrontPageImage from './front-page-image'

interface ImageFrontPageProps {
  activeTab: string
}

const ImageFrontPage: React.FunctionComponent<ImageFrontPageProps> = ({
  activeTab,
}) => {
  const [currentTab, setCurrentTab] = useState<string>()

  useEffect(() => {
    if (activeTab) {
      setCurrentTab(activeTab)
    }
  }, [activeTab])

  return (
    <ProCard
      title={'The Front Page of Spec Sheet and Paper Inspection Report'}
      // headerBordered
      layout="default"
      style={{ marginTop: 8 }}
      size={'small'}
      headerBordered
      headStyle={{
        backgroundColor: '#FADBD8',
        borderBottomColor: '#C0392B',
      }}
    >
      <Tabs
        defaultActiveKey={'INLINE'}
        activeKey={currentTab}
        size="small"
        onChange={(tab) => {
          setCurrentTab(tab)
        }}
      >
        <Tabs.TabPane tab="Inline" key="INLINE">
          <FrontPageImage reportStatus={'1'} />
          {/* <DraggableMedia items={[]} onSelectItem={() => {}} reportType={1} /> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Final" key="FINAL">
          <FrontPageImage reportStatus={'2'} />
          {/* <DraggableMedia items={[]} onSelectItem={() => {}} reportType={2} /> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Re-Final" key="RE-FINAL">
          <FrontPageImage reportStatus={'3'} />
          {/* <DraggableMedia items={[]} onSelectItem={() => {}} reportType={3} /> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Re-Final2" key="RE-FINA2">
          <FrontPageImage reportStatus={'4'} />
          {/* <DraggableMedia items={[]} onSelectItem={() => {}} reportType={3} /> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Re-Final3" key="RE-FINAL3">
          <FrontPageImage reportStatus={'5'} />
          {/* <DraggableMedia items={[]} onSelectItem={() => {}} reportType={3} /> */}
        </Tabs.TabPane>
      </Tabs>
    </ProCard>
  )
}

export default ImageFrontPage
