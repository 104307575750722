import React, { useEffect, useRef, useState } from 'react'

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  ActionType,
  PageContainer,
  ProFormInstance,
  ProTable,
} from '@ant-design/pro-components'
import { Button, Modal, Popconfirm } from 'antd'

import { customerColumns } from 'components/customer-management.columns'
import { supplierColumns } from 'components/supplier-management.columns'
import { userColumns } from 'components/user-management.columns'
import { pageTab } from 'configs/user-management/page-tab'
import {
  confirmDeleteTitle,
  createFormTitle,
} from 'configs/user-management/user-form-title'
import Customer from 'customer.types'
import { generateCustomerFilter } from 'helpers/customer'
import { generateSupplierFilter } from 'helpers/supplier'
import { generateUserFilter } from 'helpers/user'
import { DropdownParams } from 'models/master-data/dropdown'
import { countryAtom, roleState } from 'models/master-data/master-data-store'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { CustomerApi } from 'services/customer-api'
import { MasterDataApi } from 'services/master-data-api'
import { SupplierApi } from 'services/supplier-api'
import { UserApi } from 'services/user-api'
import Supplier from 'supplier.types'
import { User } from 'user/user'

import CustomerDrawer from './customer-drawer'
// import PartnerDrawer from './partner-drawer'
import SupplierDrawer from './supplier-drawer'
import UserDrawer from './user-drawer'

const { confirm } = Modal

const UserManagement = () => {
  const actionRef = useRef<ActionType>()
  const formRef = useRef<ProFormInstance>()
  const [activeKey, setActiveKey] = useState<React.Key>('add-user')
  const [activeTab, setActiveTab] = useState<
    'users' | 'customers' | 'suppliers'
  >('users')
  const [userData] = useRecoilState(userAtom)
  const [users, setUsers] = useState<User[] | []>([])
  const [customers, setCustomers] = useState<Customer[] | []>([])
  const [suppliers, setSuppliers] = useState<Supplier[] | []>([])
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerCustomerVisible, setDrawerCustomerVisible] = useState(false)
  const [drawerSupplierVisible, setDrawerSupplierVisible] = useState(false)
  const [drawerTitle, setDrawerTitle] = useState<string>('')
  const [addButtonTitle, setAddButtonTitle] = useState<string>('')
  const [currentData, setCurrentData] = useState<User | undefined>(undefined)
  const [currentCustomerData, setCurrentCustomerData] = useState<
    Customer | undefined
  >(undefined)
  const [currentSupplierData, setCurrentSupplierData] = useState<
    Supplier | undefined
  >(undefined)
  const [countryDdList, setCountryDdList] = useRecoilState(countryAtom)
  const [roleDdList, setRoleDdList] = useRecoilState(roleState)

  /**
   * ANCHOR Mutation
   */
  const countryDdMutation = useMutation(async (params: DropdownParams) => {
    const masterDataApi = useApi(MasterDataApi)
    return masterDataApi.dropdown(params)
  })

  const roleDdMutation = useMutation(async (params: DropdownParams) => {
    const masterDataApi = useApi(MasterDataApi)
    return masterDataApi.dropdown(params)
  })

  const usersMutation = useMutation(async (data: any) => {
    const userApi = useApi(UserApi)
    return userApi.getUsers(data)
  })

  const deleteUserMutation = useMutation(async (data: any) => {
    const userApi = useApi(UserApi)
    return userApi.deleteUser(data)
  })

  const customersMutation = useMutation(async (data: any) => {
    const customerApi = useApi(CustomerApi)
    return customerApi.list(data)
  })

  const deleteCustomerMutation = useMutation(async (data: any) => {
    const customerApi = useApi(CustomerApi)
    return customerApi.delete(data)
  })

  const suppliersMutation = useMutation(async (data: any) => {
    const supplierApi = useApi(SupplierApi)
    return supplierApi.list(data)
  })

  const deleteSupplierMutation = useMutation(async (data: any) => {
    const supplierApi = useApi(SupplierApi)
    return supplierApi.delete(data)
  })

  /**
   * ANCHOR Hook
   */

  useEffect(() => {
    countryDdMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        option: 'country',
      },
    })
    roleDdMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        option: 'role',
      },
    })
  }, [])

  // ANCHOR on activeTab has change
  useEffect(() => {
    setAddButtonTitle(createFormTitle[activeTab])
    setDrawerTitle(createFormTitle[activeTab])
    formRef.current?.resetFields()
    actionRef.current?.reload()
  }, [activeTab])

  // ANCHOR Role Dropdown
  useEffect(() => {
    if (!roleDdMutation.isError) {
      const roleResult = roleDdMutation.data

      if (roleResult?.kind == 'ok') {
        let roleList = roleResult.data || []

        let roleOptions = roleList.result.map((e: any) => {
          return { label: e.value, value: e.id }
        })

        setRoleDdList(roleOptions)
      }
    }
  }, [roleDdMutation.data, roleDdMutation.isError])

  // ANCHOR Country Dropdown
  useEffect(() => {
    if (!countryDdMutation.isError) {
      const countryResult = countryDdMutation.data

      if (countryResult?.kind == 'ok') {
        let countryList = countryResult.data || []

        let countryOptions = countryList.result.map((e: any) => {
          return { label: e.value, value: e.id }
        })
        setCountryDdList(countryOptions)
      }
    }
  }, [countryDdMutation.data, countryDdMutation.isError])

  useEffect(() => {
    const getUsersResult = usersMutation.data

    if (getUsersResult?.kind == 'ok') {
      let usersList = getUsersResult.data || []
      usersList = usersList.map((e: any) => {
        e.key = e.id
        return e
      })

      setUsers(usersList)
    }
  }, [usersMutation.data, usersMutation.isError])

  useEffect(() => {
    if (
      deleteUserMutation.isLoading == false &&
      deleteUserMutation.isError == false
    ) {
      actionRef.current?.reload()
    }
  }, [deleteUserMutation.isLoading, deleteUserMutation.isError])

  useEffect(() => {
    const getCustomersResult = customersMutation.data

    if (getCustomersResult?.kind == 'ok') {
      let customersList = getCustomersResult.data || []
      setCustomers(customersList)
    }
  }, [customersMutation.data, customersMutation.isError])

  useEffect(() => {
    if (
      deleteCustomerMutation.isLoading == false &&
      deleteCustomerMutation.isError == false
    ) {
      actionRef.current?.reload()
    }
  }, [deleteCustomerMutation.isLoading, deleteCustomerMutation.isError])

  useEffect(() => {
    const getSupplierResult = suppliersMutation.data

    if (getSupplierResult?.kind == 'ok') {
      let supplierList = getSupplierResult.data || []
      setSuppliers(supplierList)
    }
  }, [suppliersMutation.data, suppliersMutation.isError])

  useEffect(() => {
    if (
      deleteSupplierMutation.isLoading == false &&
      deleteSupplierMutation.isError == false
    ) {
      actionRef.current?.reload()
    }
  }, [deleteSupplierMutation.isLoading, deleteSupplierMutation.isError])

  /**
   * ANCHOR Columns
   */

  const getColumns = () => {
    let countryList: any = {}
    countryDdList.forEach((element: any) => {
      countryList[element.value] = element.label
    })

    let roleList: any = {}

    if (activeTab == 'users') {
      roleDdList.forEach((element: any) => {
        roleList[element.value] = element.label
      })

      return userColumns(
        roleList,
        countryList,
        activeTab,
        setCurrentData,
        setDrawerVisible,
        setDrawerTitle,
        deleteRecord,
      )
    } else if (activeTab == 'customers') {
      return customerColumns(
        countryList,
        activeTab,
        setCurrentCustomerData,
        setDrawerCustomerVisible,
        setDrawerTitle,
        deleteRecord,
      )
    } else if (activeTab == 'suppliers') {
      return supplierColumns(
        countryList,
        activeTab,
        setCurrentSupplierData,
        setDrawerSupplierVisible,
        setDrawerTitle,
        deleteRecord,
      )
    }
  }

  /**
   * ANCHOR getData
   * @returns
   */

  const getData = () => {
    switch (activeTab) {
      case 'users':
        return users
        break
      case 'customers':
        return customers
        break
      case 'suppliers':
        return suppliers
      default:
        return []
        break
    }
  }

  /**
   * ANCHOR Request Logic
   * @param params
   * @param _sort
   * @param _filter
   * @returns
   */
  const request = async (params: any, _sort: any, _filter: any) => {
    if (activeTab == 'users') {
      const where = generateUserFilter(params, activeTab)
      await usersMutation.mutateAsync({
        headers: {
          'x-access-token': userData.token,
        },
        params: {
          offset: 0,
          limit: 1000,
        },
        body: {
          where: where,
        },
      })
    } else if (activeTab == 'customers') {
      console.log(params)
      const where = generateCustomerFilter(params, activeTab)
      await customersMutation.mutateAsync({
        headers: {
          'x-access-token': userData.token,
        },
        params: {
          offset: 0,
          limit: 1000,
        },
        body: {
          where: where,
        },
      })
    } else if (activeTab == 'suppliers') {
      console.log(params)
      const where = generateSupplierFilter(params, activeTab)
      await suppliersMutation.mutateAsync({
        headers: {
          'x-access-token': userData.token,
        },
        params: {
          offset: 0,
          limit: 1000,
        },
        body: {
          where: where,
        },
      })
    }
    return []
  }

  /**
   * ANCHOR Delete Record
   * @param record
   */
  const deleteRecord = async (record: any) => {
    showDeleteConfirm(record)
  }

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: confirmDeleteTitle[activeTab],
      icon: <ExclamationCircleOutlined />,
      content: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{`Name: ${record.profile_name}`}</span>
          <span>{`Country: ${record.country_name}`}</span>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        const params = await (() => {
          console.log(record)
          if (activeTab == 'users') {
            return { profile_id: record.user_id }
          } else if (activeTab == 'customers') {
            return { customer_id: record.customer_id }
          } else if (activeTab == 'suppliers') {
            return { supplier_id: record.supplier_id }
          }
        })()
        console.log(params)
        const willDeletedRecord = {
          headers: {
            'x-access-token': userData.token,
          },
          params: params,
        }
        if (activeTab == 'users') {
          await deleteUserMutation.mutateAsync(willDeletedRecord)
        } else if (activeTab == 'customers') {
          await deleteCustomerMutation.mutateAsync(willDeletedRecord)
        } else if (activeTab == 'suppliers') {
          await deleteSupplierMutation.mutateAsync(willDeletedRecord)
        }
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumb: {
          routes: [
            {
              path: '',
              breadcrumbName: 'User Management',
            },
          ],
        },
      }}
      tabList={pageTab}
      onTabChange={(key: any) => {
        setActiveTab(key)
      }}
    >
      {/**
       * ANCHOR Drawer
       */}
      <UserDrawer
        title={drawerTitle}
        visible={drawerVisible}
        onClose={() => {
          setDrawerVisible(false)
          actionRef.current?.reload()
        }}
        data={currentData}
      ></UserDrawer>
      <CustomerDrawer
        title={drawerTitle}
        visible={drawerCustomerVisible}
        onClose={() => {
          setDrawerCustomerVisible(false)
          actionRef.current?.reload()
        }}
        data={currentCustomerData}
      ></CustomerDrawer>
      <SupplierDrawer
        title={drawerTitle}
        visible={drawerSupplierVisible}
        onClose={() => {
          setDrawerSupplierVisible(false)
          actionRef.current?.reload()
        }}
        data={currentSupplierData}
      ></SupplierDrawer>
      {/**
       * ANCHOR User Table
       */}

      <ProTable<User | Customer | Supplier>
        columns={getColumns()}
        actionRef={actionRef}
        formRef={formRef}
        dataSource={getData()}
        request={request}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
          onChange(value) {
            console.log('value: ', value)
          },
        }}
        rowKey="user_id"
        pagination={{
          pageSize: 8,
          onChange: (page) => console.log(page),
        }}
        dateFormatter="string"
        options={{
          density: true,
        }}
        search={{
          labelWidth: 'auto',
        }}
        toolbar={{
          menu: {
            type: 'inline',
            activeKey: activeKey,
            items: [
              {
                key: 'add_user',
                label: (
                  /**
                   * ANCHOR Add Button
                   */
                  <Button
                    key="button"
                    icon={<PlusOutlined />}
                    type="primary"
                    style={{
                      backgroundColor: '#10c469',
                      borderColor: '#10c469',
                    }}
                    onClick={() => {
                      switch (activeTab) {
                        case 'users':
                          setCurrentData(undefined)
                          setDrawerVisible(true)
                          break
                        case 'customers':
                          setCurrentCustomerData(undefined)
                          setDrawerCustomerVisible(true)
                          break
                        case 'suppliers':
                          setCurrentSupplierData(undefined)
                          setDrawerSupplierVisible(true)
                          break
                        default:
                          break
                      }
                    }}
                  >
                    {addButtonTitle}
                  </Button>
                ),
              },
            ],
          },
        }}
      />
      <Popconfirm
        title="Confirm"
        visible={confirmVisible}
        // onConfirm={handleOk}
        // okButtonProps={{ loading: confirmLoading }}
        // onCancel={handleCancel}
      ></Popconfirm>
    </PageContainer>
  )
}

export default UserManagement
