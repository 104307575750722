import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react'

import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  PageContainer,
  ProCard,
  ProDescriptions,
} from '@ant-design/pro-components'
import { Button, Radio, Tabs, TabsProps } from 'antd'
import confirm from 'antd/lib/modal/confirm'

import DraggableMedia from 'components/dragable-media'
import { Link } from 'gatsby'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { AqlApi } from 'services/aql-api'
import { MasterDataApi } from 'services/master-data-api'

import FileEditor from './file-editor'
import FileUploader from './file-uploader'

interface ItemReportImageProps {}

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => {
  console.log(props)
  return <DefaultTabBar {...props}>{(node) => node}</DefaultTabBar>
}

const ItemReportImage: React.FC<ItemReportImageProps> = () => {
  const [userData] = useRecoilState(userAtom)
  const [tabList, setTabList] = useState([])
  const [imageList, setImageList] = useState<any>({})
  const [currentItemData, setCurrentItemData] = useState<any>({})
  const [currentSelectItem, setCurrentSelectItem] = useState<any>()
  const [activeKey, setActiveKey] = useState('1')
  const [previewActiveKey, setPreviewActiveKey] = useState('1')
  const [inspectionId, setInspectionId] = useState()
  const [actionList, setActionList] = useState<string[]>([])
  const [deleteList, setDeleteList] = useState<any[]>([])
  const [isLoadingImageList, setIsLoadingImageList] = useState(false)
  const [draggableMaxItems, setDraggableAreaMaxItems] = useState(3)
  const draggableArea = useRef(null)
  const mediaArea = useRef(null)
  // const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)
  const [draggableKey, setDraggableKey] = useState(0)

  /**
   * ANCHOR Mutation
   */

  const itemMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.viewItem(data)
  })

  const tabTypeMutation = useMutation(async (data: any) => {
    const api = useApi(MasterDataApi)
    return api.dropdown(data)
  })

  const imageListMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.ItemListImage(data)
  })

  const deletePhotoMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.itemDeleteImage(data)
  })

  /**
   * ANCHOR useEffect
   */
  useEffect(() => {
    let al = userData.menu_action?.filter(
      (e: any) => e.path == 'aql-inspection',
    )
    al = al.length ? al[0].action_list : []
    console.log(al)
    setActionList(al)

    const pageParams = new URLSearchParams(location.search)
    const inspection_id = pageParams.get('inspection_id')
    const item_id = pageParams.get('item_id')

    setInspectionId(inspection_id)

    itemMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        inspection_id,
        item_id,
      },
    })

    tabTypeMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        option: 'photo_type',
      },
    })

    setDraggableAreaMaxItems(
      Math.floor((mediaArea.current.clientWidth - 933 - 250) / 110),
    )

    function handleWindowResize() {
      setDraggableAreaMaxItems(
        Math.floor((mediaArea.current.clientWidth - 933) / 110),
      )
      setDraggableKey((currentKey) => currentKey + 1)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (!tabTypeMutation.isError && tabTypeMutation.data) {
      if (tabTypeMutation.data.kind == 'ok') {
        setTabList(tabTypeMutation?.data?.data?.result || [])
      }
    }
  }, [tabTypeMutation.data, tabTypeMutation.isError])

  useEffect(() => {
    if (!deletePhotoMutation.isError && deletePhotoMutation.data) {
      if (deletePhotoMutation.data.kind == 'ok') {
        // setTabList(deletePhotoMutation.data.data.result)

        reloadMediaList()
      }
    }
  }, [deletePhotoMutation.data, deletePhotoMutation.isError])

  useEffect(() => {
    if (!itemMutation.isError && itemMutation.data) {
      if (
        itemMutation.data.kind == 'ok' &&
        itemMutation.data.data.result.length
      ) {
        const pageParams = new URLSearchParams(location.search)

        const report_status = pageParams.get('report_status')
        let data = itemMutation.data.data.result[0]
        data.type = report_status?.toUpperCase()
        setCurrentItemData(data)
      }
    }
  }, [itemMutation.data, itemMutation.isError])

  useEffect(() => {
    if (!imageListMutation.isError && imageListMutation.data) {
      // console.log(imageListMutation.data)
      setIsLoadingImageList(false)
      if (imageListMutation.data.kind == 'ok') {
        let result = imageListMutation.data.data.result
        if (result.list_item?.length) {
          setImageList(result.list_item[0])
        } else {
          setImageList({})
        }
        setDeleteList([])
      }
    }
  }, [imageListMutation.data, imageListMutation.isError])

  useEffect(() => {
    reloadMediaList(activeKey)
  }, [activeKey])

  // useEffect(() => {
  //   console.log(imageList[0])
  // }, [imageList])

  /**
   * ANCHOR Functions
   */

  const onSelectItem = (item: any) => {
    // console.log('onselectitem', item)
    setCurrentSelectItem(item)
    setPreviewActiveKey('1')
  }

  const onCheckDelete = (item: any, checked: boolean) => {
    if (checked) {
      setDeleteList([...deleteList, item.photo_id])
    } else {
      setDeleteList(deleteList.filter((e) => e !== item.photo_id))
    }
  }

  const reloadMediaList = (ak?: any) => {
    const pageParams = new URLSearchParams(location.search)
    const inspection_id = pageParams.get('inspection_id')
    let item_id: any = pageParams.get('item_id')
    item_id = parseInt(item_id)
    const report_status = pageParams.get('report_status')
    const rs = { inline: 1, final: 2, refinal: 3, refinal2: 4, refinal3: 5 }

    if (inspection_id && !isNaN(parseInt(inspection_id))) {
      setIsLoadingImageList(true)
      imageListMutation.mutate({
        headers: {
          'x-access-token': userData.token,
        },
        params: {
          inspection_id: inspection_id,
          report_status: rs[report_status],
        },
        body: {
          list_item_id: [item_id],
          // list_item_tab: ak ? [parseInt(ak)] : [1],
        },
      })
    }
  }

  const deletePhotos = () => {
    confirm({
      title: 'Do you want to delete these items?',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      content: '',
      onOk() {
        deletePhotoMutation.mutate({
          headers: {
            'x-access-token': userData.token,
          },
          params: {
            photo_type: 'photo_item_inspection',
          },
          body: {
            photo_id: deleteList,
          },
        })
      },
      onCancel() {},
    })
  }

  const onUpdateSuccess = () => {
    console.log('update')
    reloadMediaList()
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumb: {
          itemRender(route, params, routes, paths) {
            if (route.path) {
              return <Link to={route.path}>{route.breadcrumbName}</Link>
            } else {
              return route.breadcrumbName
            }
          },
          routes: [
            {
              path: '/aql-inspection',
              breadcrumbName: 'AQL Inspection',
            },
            {
              path: '/aql-inspection/view?id=' + inspectionId,
              breadcrumbName: 'Inspection Detail',
            },
            {
              path: '',
              breadcrumbName: 'Images',
            },
          ],
        },
      }}
    >
      <ProCard size="small">
        <ProDescriptions
          // actionRef={actionRef}
          // bordered
          labelStyle={{ color: '#188ae2' }}
          formProps={{
            onValuesChange: (e, f) => console.log(f),
          }}
          title="Item Details"
          // request={async () => {

          // }}
          dataSource={currentItemData}
          columns={[
            {
              title: 'P.O. No.',
              key: 'po_number',
              dataIndex: 'po_number',
            },
            {
              title: 'Item No.',
              key: 'item_no',
              dataIndex: 'item_no',
            },
            {
              title: 'Color',
              key: 'color',
              dataIndex: 'color',
            },
            {
              title: 'Description',
              key: 'item_description',
              dataIndex: 'item_description',
            },
            {
              title: 'Supplier',
              key: 'supplier',
              dataIndex: 'supplier_name',
            },
            {
              title: 'Type',
              key: 'type',
              dataIndex: 'type',
            },
          ]}
        ></ProDescriptions>
      </ProCard>
      <ProCard
        layout="default"
        style={{ marginTop: 20 }}
        size={'small'}
        ref={mediaArea}
      >
        <Tabs
          type="card"
          tabPosition={'left'}
          tabBarStyle={{ width: 530 }}
          size={'small'}
          onTabClick={(activeKey) => {
            setCurrentSelectItem(null)
            // console.log(activeKey)
            setActiveKey(activeKey)
          }}
          destroyInactiveTabPane={true}
        >
          {tabList.map((e: any) => {
            return (
              <Tabs.TabPane
                tab={
                  e.value
                  // e.value.length > 14
                  //   ? e.value.substring(0, 14) + '...'
                  //   : e.value
                }
                key={e.id}
                // style={{ width: 100 }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      display: 'flex',
                      marginRight: 5,
                      flex: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ padding: 5, margin: '5px, 0' }}>
                        {e.value}
                      </div>
                      <div>
                        {deleteList.length > 0 ? (
                          <Button
                            type="primary"
                            danger
                            onClick={() => {
                              deletePhotos()
                            }}
                          >
                            <DeleteOutlined />
                            {` ${deleteList.length} items`}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <div ref={draggableArea} key={draggableKey}>
                      <DraggableMedia
                        items={
                          Object.keys(imageList).length
                            ? imageList['' + e.id]?.list_photo || []
                            : []
                        }
                        onSelectItem={onSelectItem}
                        onCheckDelete={onCheckDelete}
                        isLoading={isLoadingImageList}
                        maxItems={draggableMaxItems}
                      />
                    </div>
                  </div>
                  {actionList.includes('edit') ||
                  actionList.includes('upload') ? (
                    <div
                      style={{
                        display: 'flex',
                        // flex: 1,
                        width: 350,
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        borderLeft: '1px solid #eaeaea',
                        padding: 5,
                        paddingLeft: 10,
                        // backgroundColor: 'red',
                      }}
                    >
                      <Tabs
                        defaultActiveKey="1"
                        onChange={() => {}}
                        type="card"
                        style={{ width: '100%' }}
                        activeKey={previewActiveKey}
                        onTabClick={(pActiveKey) => {
                          setPreviewActiveKey(pActiveKey)
                        }}
                      >
                        <Tabs.TabPane tab="Preview & Edit" key="1">
                          <FileEditor
                            mediaType="photo"
                            item={currentSelectItem}
                            onUpdateSuccess={onUpdateSuccess}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Upload" key="2">
                          <FileUploader
                            mediaType="photo"
                            mediaGroup={activeKey}
                            onSuccess={onUpdateSuccess}
                          />
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  ) : null}
                </div>
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </ProCard>
    </PageContainer>
  )
}

export default ItemReportImage
