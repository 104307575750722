export const createFormTitle: { [key: string]: string } = {
  users: 'Add User',
  customers: 'Add Customer',
  suppliers: 'Add Supplier',
}

export const updateFormTitle: { [key: string]: string } = {
  users: 'Update User Information',
  customers: 'Update Customer Information',
  suppliers: 'Update Supplier Information',
}

export const confirmDeleteTitle: { [key: string]: string } = {
  users: 'Are you sure delete this user?',
  customers: 'Are you sure delete this customer?',
  suppliers: 'Are you sure delete this supplier?',
}
