import * as React from 'react'

import { ProCard } from '@ant-design/pro-components'
import { Button, Col, Row, Statistic } from 'antd'

import { Link } from 'gatsby'

const DashboardPage = () => {
  return (
    <ProCard title="AQL Inspection">
      <Row gutter={24}>
        <Col span={8}>
          <Statistic title="On Hold" value={'---'} />
        </Col>
        <Col span={8}>
          <Statistic title="Fail" value={'---'} />
        </Col>
        <Col span={8}>
          <Statistic title="Pass" value={'---'} />
        </Col>
      </Row>
    </ProCard>
  )
}

export default DashboardPage
