import React, { useEffect, useRef, useState } from 'react'

import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import {
  ActionType,
  PageContainer,
  ProCard,
  ProTable,
} from '@ant-design/pro-components'
import { Badge, Button, Space, Spin, Table, message } from 'antd'

import AssignPo from 'assign-po.types'
import AssignItem from 'assign.types'
import { columnsPo } from 'components/assign-po.columns'
import { columnsAssignment } from 'components/assign.columns'
import SKBreadcrumb from 'components/sk-breadcumb'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { AssignmentApi } from 'services/assignment-api'
import request from 'umi-request'
import { User } from 'user/user'

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 15, color: 'white' }}
    color="white"
    spin
  />
)

const AssignmentPage = () => {
  const [userData] = useRecoilState(userAtom)
  const [userList, setUserList] = useState<User[]>([])
  const [assignmentList, setAssignmentList] = useState([])
  const [selectedUser, setSelectedUser] = useState([])
  const [selectedPO, setSelectedPO] = useState([])

  const actionRefUser = useRef<ActionType>()
  const actionRefPO = useRef<ActionType>()

  const userMutation = useMutation(async (data: any) => {
    const api = useApi(AssignmentApi)
    return api.userList(data)
  })

  const assignmentListMutation = useMutation(async (data: any) => {
    const api = useApi(AssignmentApi)
    return api.list(data)
  })

  const addAssignmentMutation = useMutation(async (data: any) => {
    const api = useApi(AssignmentApi)
    return api.add(data)
  })

  useEffect(() => {
    addAssignmentMutation.reset()
    actionRefUser.current?.clearSelected()
    actionRefPO.current?.clearSelected()
  }, [])

  useEffect(() => {
    if (
      !addAssignmentMutation.isLoading &&
      !addAssignmentMutation.isError &&
      addAssignmentMutation.data?.kind == 'ok'
    ) {
      message.success('Successful adding an assignment.')
    }
    actionRefPO.current?.clearSelected()
    actionRefUser.current?.clearSelected()
    actionRefPO.current?.reload()
    // console.log(addAssignmentMutation.data)
  }, [addAssignmentMutation.isError, addAssignmentMutation.data])

  const requestUser = async (params: any, sort: any, filter: any) => {
    console.log(params, sort, filter)

    const filters = Object.keys(params).map((e) => {
      return {
        fields: e,
        value: params[e],
        type: 'string',
      }
    })

    const data = await userMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: filters,
      },
      params: {
        limit: 100,
        offset: 0,
      },
    })
    if (data.kind == 'ok') {
      console.log(data.data)
      setUserList(data.data)
      return data.data
    }
    return []
  }

  const requestPO = async (params: any, sort: any, filter: any) => {
    console.log(params, sort, filter)

    const filters = Object.keys(params)
      .filter((e) => e == 'po_number')
      .map((e) => {
        return {
          fields: e,
          value: params[e],
          type: 'string',
        }
      })

    const result = await assignmentListMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: [
          ...filters,
          {
            fields: 'assignment_status',
            value: '0',
            type: 'int',
          },
        ],
      },
    })
    if (result.kind == 'ok') {
      setAssignmentList(result.data)
    }
    return []
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumbRender(props, defaultDom) {
          return <SKBreadcrumb routes={props?.breadcrumb?.routes || []} />
        },
        breadcrumb: {
          routes: [
            {
              path: '/assignment',
              breadcrumbName: 'Assignment',
            },
            {
              path: '',
              breadcrumbName: 'Add Assignment',
            },
          ],
        },
      }}
    >
      <ProCard
        title={
          <Space>
            {'Selected User: '}
            <Badge
              count={selectedUser.length}
              showZero
              style={{ backgroundColor: 'green' }}
            />
            {'Selected Inspections: '}
            <Badge
              count={selectedPO.length}
              showZero
              style={{ backgroundColor: 'green' }}
            />
          </Space>
        }
        extra={
          <Button
            key="assign"
            type="primary"
            size="medium"
            style={{ backgroundColor: '#10c469', borderColor: '#10c469' }}
            onClick={() => {
              if (selectedUser.length && selectedPO.length) {
                addAssignmentMutation.mutate({
                  headers: {
                    'x-access-token': userData.token,
                  },
                  body: {
                    assignment_user_id: selectedUser[0],
                    list_inspection_id: selectedPO,
                  },
                })
              } else {
                message.error('Please select user and inspections.')
              }
            }}
          >
            <Spin
              size="small"
              spinning={addAssignmentMutation.isLoading}
              indicator={antIcon}
              style={{ marginRight: 5 }}
            />
            <span>Submit</span>
          </Button>
        }
        split="vertical"
        headerBordered
      >
        <ProCard
          layout="center"
          colSpan={{
            xs: '50px',
            sm: '100px',
            md: '200px',
            lg: '300px',
            xl: '400px',
          }}
          ghost
        >
          <ProTable<any>
            columns={columnsAssignment}
            actionRef={actionRefUser}
            rowSelection={{
              // Custom selection reference: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
              // Comment this line and the drop-down option will not be displayed by default
              type: 'radio',
              onChange(selectedRowKeys, selectedRows, info) {
                setSelectedUser(selectedRowKeys)
              },
              onSelect: (record) => {
                console.log(record.name)
              },
            }}
            tableAlertRender={({
              selectedRowKeys,
              selectedRows,
              onCleanSelected,
            }) => {
              // setSelectedUser(selectedRows?.map((e) => e.id) || [])
              return (
                <Space size={24}>
                  <span>
                    <CheckCircleFilled style={{ color: 'green' }} />
                    {` ${
                      selectedRows && selectedRows.length
                        ? selectedRows[0].username
                        : ''
                    }`}
                  </span>
                </Space>
              )
            }}
            // onRow={(record) => {
            //   return {
            //     onClick: () => {
            //       console.log(record.username);
            //     },
            //   };
            // }}
            dataSource={userList}
            search={{
              filterType: 'light',
            }}
            request={requestUser}
            rowKey="assignment_user_id"
            headerTitle="Select User"
            toolbar={
              {
                // search: {
                //   onSearch: (value) => {
                //     alert(value);
                //   },
                // },
                // actions: [
                //   <Button key=" list " type=" primary ">
                //     Assignment
                //   </Button>,
                // ],
              }
            }
            options={false}
            pagination={false}
            scroll={{ y: 400 }}
          />
        </ProCard>

        <ProCard ghost>
          <ProTable<AssignPo>
            tableLayout="auto"
            columns={columnsPo}
            actionRef={actionRefPO}
            rowSelection={{
              // Custom selection reference: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
              // Comment this line and the drop-down option will not be displayed by default
              // type: "checkbox",
              onChange(selectedRowKeys, selectedRows, info) {
                setSelectedPO(selectedRowKeys)
              },
              onSelect: (record) => {
                console.log(record.po_number)
              },
              selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
              // defaultSelectedRowKeys: [1],
            }}
            search={{
              filterType: 'light',
            }}
            tableAlertRender={({
              selectedRowKeys,
              selectedRows,
              onCleanSelected,
            }) => {
              //
              return (
                <Space size={24}>
                  <span>
                    <CheckCircleFilled style={{ color: 'green' }} />
                    {` ${
                      selectedRows && selectedRows.length
                        ? selectedRows.map((e) => e.po_number).join(', ')
                        : ''
                    }`}
                  </span>
                </Space>
              )
            }}
            // onRow={(record) => {
            //   return {
            //     onClick: () => {
            //       console.log(record.username);
            //     },
            //   };
            // }}
            dataSource={assignmentList}
            request={requestPO}
            rowKey="id"
            headerTitle="Select Inspections"
            toolbar={
              {
                // search: {
                //   onSearch: (value) => {
                //     alert(value);
                //   },
                // },
                // actions: [
                //   <Button key="list" type="primary">
                //     Assignment
                //   </Button>,
                // ],
              }
            }
            options={false}
            pagination={{
              pageSize: 5,
            }}
            // scroll={{ x: 800 }}
          />
          {/* </div> */}
        </ProCard>
      </ProCard>
    </PageContainer>
  )
}

export default AssignmentPage
