exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aql-inspection-add-new-drawer-tsx": () => import("./../../../src/pages/aql-inspection/add-new-drawer.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-add-new-drawer-tsx" */),
  "component---src-pages-aql-inspection-file-editor-tsx": () => import("./../../../src/pages/aql-inspection/file-editor.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-file-editor-tsx" */),
  "component---src-pages-aql-inspection-file-uploader-tsx": () => import("./../../../src/pages/aql-inspection/file-uploader.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-file-uploader-tsx" */),
  "component---src-pages-aql-inspection-front-page-image-tsx": () => import("./../../../src/pages/aql-inspection/front-page-image.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-front-page-image-tsx" */),
  "component---src-pages-aql-inspection-image-frontpage-tsx": () => import("./../../../src/pages/aql-inspection/image-frontpage.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-image-frontpage-tsx" */),
  "component---src-pages-aql-inspection-index-tsx": () => import("./../../../src/pages/aql-inspection/index.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-index-tsx" */),
  "component---src-pages-aql-inspection-item-drawer-tsx": () => import("./../../../src/pages/aql-inspection/item-drawer.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-item-drawer-tsx" */),
  "component---src-pages-aql-inspection-item-medias-tsx": () => import("./../../../src/pages/aql-inspection/item-medias.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-item-medias-tsx" */),
  "component---src-pages-aql-inspection-item-report-defect-tsx": () => import("./../../../src/pages/aql-inspection/item-report-defect.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-item-report-defect-tsx" */),
  "component---src-pages-aql-inspection-item-report-image-tsx": () => import("./../../../src/pages/aql-inspection/item-report-image.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-item-report-image-tsx" */),
  "component---src-pages-aql-inspection-item-report-video-tsx": () => import("./../../../src/pages/aql-inspection/item-report-video.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-item-report-video-tsx" */),
  "component---src-pages-aql-inspection-items-table-tsx": () => import("./../../../src/pages/aql-inspection/items-table.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-items-table-tsx" */),
  "component---src-pages-aql-inspection-signature-modal-tsx": () => import("./../../../src/pages/aql-inspection/signature-modal.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-signature-modal-tsx" */),
  "component---src-pages-aql-inspection-view-tsx": () => import("./../../../src/pages/aql-inspection/view.tsx" /* webpackChunkName: "component---src-pages-aql-inspection-view-tsx" */),
  "component---src-pages-assignment-add-assignment-tsx": () => import("./../../../src/pages/assignment/add-assignment.tsx" /* webpackChunkName: "component---src-pages-assignment-add-assignment-tsx" */),
  "component---src-pages-assignment-index-tsx": () => import("./../../../src/pages/assignment/index.tsx" /* webpackChunkName: "component---src-pages-assignment-index-tsx" */),
  "component---src-pages-auth-login-index-tsx": () => import("./../../../src/pages/auth/login/index.tsx" /* webpackChunkName: "component---src-pages-auth-login-index-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-monthly-summary-index-tsx": () => import("./../../../src/pages/monthly-summary/index.tsx" /* webpackChunkName: "component---src-pages-monthly-summary-index-tsx" */),
  "component---src-pages-setting-index-tsx": () => import("./../../../src/pages/setting/index.tsx" /* webpackChunkName: "component---src-pages-setting-index-tsx" */),
  "component---src-pages-shipment-document-edit-sharing-tsx": () => import("./../../../src/pages/shipment-document/edit-sharing.tsx" /* webpackChunkName: "component---src-pages-shipment-document-edit-sharing-tsx" */),
  "component---src-pages-shipment-document-file-viewer-tsx": () => import("./../../../src/pages/shipment-document/file-viewer.tsx" /* webpackChunkName: "component---src-pages-shipment-document-file-viewer-tsx" */),
  "component---src-pages-shipment-document-index-tsx": () => import("./../../../src/pages/shipment-document/index.tsx" /* webpackChunkName: "component---src-pages-shipment-document-index-tsx" */),
  "component---src-pages-shipment-document-upload-tsx": () => import("./../../../src/pages/shipment-document/upload.tsx" /* webpackChunkName: "component---src-pages-shipment-document-upload-tsx" */),
  "component---src-pages-spec-sheet-edit-sharing-tsx": () => import("./../../../src/pages/spec-sheet/edit-sharing.tsx" /* webpackChunkName: "component---src-pages-spec-sheet-edit-sharing-tsx" */),
  "component---src-pages-spec-sheet-file-viewer-tsx": () => import("./../../../src/pages/spec-sheet/file-viewer.tsx" /* webpackChunkName: "component---src-pages-spec-sheet-file-viewer-tsx" */),
  "component---src-pages-spec-sheet-index-tsx": () => import("./../../../src/pages/spec-sheet/index.tsx" /* webpackChunkName: "component---src-pages-spec-sheet-index-tsx" */),
  "component---src-pages-spec-sheet-upload-tsx": () => import("./../../../src/pages/spec-sheet/upload.tsx" /* webpackChunkName: "component---src-pages-spec-sheet-upload-tsx" */),
  "component---src-pages-summary-of-order-index-tsx": () => import("./../../../src/pages/summary-of-order/index.tsx" /* webpackChunkName: "component---src-pages-summary-of-order-index-tsx" */),
  "component---src-pages-user-management-customer-drawer-tsx": () => import("./../../../src/pages/user-management/customer-drawer.tsx" /* webpackChunkName: "component---src-pages-user-management-customer-drawer-tsx" */),
  "component---src-pages-user-management-edit-profile-tsx": () => import("./../../../src/pages/user-management/edit-profile.tsx" /* webpackChunkName: "component---src-pages-user-management-edit-profile-tsx" */),
  "component---src-pages-user-management-index-tsx": () => import("./../../../src/pages/user-management/index.tsx" /* webpackChunkName: "component---src-pages-user-management-index-tsx" */),
  "component---src-pages-user-management-supplier-drawer-tsx": () => import("./../../../src/pages/user-management/supplier-drawer.tsx" /* webpackChunkName: "component---src-pages-user-management-supplier-drawer-tsx" */),
  "component---src-pages-user-management-user-drawer-tsx": () => import("./../../../src/pages/user-management/user-drawer.tsx" /* webpackChunkName: "component---src-pages-user-management-user-drawer-tsx" */)
}

