import { ApiResponse } from 'apisauce'
import { GetCustomersResult } from 'customer.types'
import { CreateCustomerParams, UpdateCustomerParams } from 'customer/customer'
import { Api } from 'services/api-integrations/api'
import { getGeneralApiProblem } from 'services/api-integrations/api-problem'

const API_PAGE_SIZE = 50

export class CustomerApi extends Api {
  /**
   * ANCHOR Update customer
   */
  async update(params: UpdateCustomerParams) {
    // const [userData] = useRecoilState(userAtom)
    const headers = params.headers

    const response: ApiResponse<any> = await this.apisauce.put(
      `/customer`,
      params.payload,
      {
        headers: {
          'x-access-token': headers.token,
        },
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        throw new Error(`${response.data?.message}`)
      }
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }

  /**
   * ANCHOR Create Customer
   */
  async create(params: CreateCustomerParams) {
    // const [userData] = useRecoilState(userAtom)
    const headers = params.headers

    const response: ApiResponse<any> = await this.apisauce.post(
      `/customer`,
      params.payload,
      {
        headers: {
          'x-access-token': headers.token,
        },
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        throw new Error(`${response.data?.message}`)
      }
    }
    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }
  /**
   * ANCHOR Gets a list of customers.
   */
  async list(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<GetCustomersResult> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.post(
      `/listcustomer`,
      data.body,
      {
        headers: data.headers,
        params: data.params,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }

  /**
   * ANCHOR Delete cistp,er.
   */
  async delete(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<GetCustomersResult> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/customer`,
      data.params,
      {
        headers: data.headers,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }
}
