import React, { Suspense, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { RecoilRoot, useRecoilSnapshot } from 'recoil'

import ParentLayout from './parent-layout'

// import { DefaultTheme, ThemeProvider } from 'styled-components';
// import { withTrans } from '../i18n/withTrans';
// import { Provider, rootStore } from '../stores/root-store';
// import ParentLayout from './parent-layout';
// import { navigate, Link } from "gatsby"
// import ProLayout, { PageContainer } from '@ant-design/pro-layout';
// import { userState } from 'stores/user';

const queryClient = new QueryClient()

function DebugObserver() {
  const snapshot = useRecoilSnapshot()
  useEffect(() => {
    console.debug('The following atoms were modified:')
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node))
    }
  }, [snapshot])

  return null
}

const LayoutPage: React.FC<{
  location: any
  pageContext: { layout: string }
  custom404: any
  children: any
}> = ({ custom404, children, pageContext, location }) => {
  if (typeof window !== 'undefined') {
    return (
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          {/* <DebugObserver /> */}
          <Suspense fallback={<div>Loading...</div>}>
            <ParentLayout
              pageContext={pageContext}
              location={location}
              custom404={custom404}
            >
              {children}
            </ParentLayout>
          </Suspense>
        </RecoilRoot>
      </QueryClientProvider>
    )
  }
  return <></>
}

export default LayoutPage
