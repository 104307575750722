import { ApiResponse } from 'apisauce'
import { GetCustomersResult } from 'customer.types'
import { CreateCustomerParams, UpdateCustomerParams } from 'customer/customer'
import { Api } from 'services/api-integrations/api'
import { getGeneralApiProblem } from 'services/api-integrations/api-problem'

import { GetUsersResult } from './api-integrations/api.types'

const API_PAGE_SIZE = 50

export class AssignmentApi extends Api {
  /**
   * ANCHOR Gets a list of po.
   */
  async poList(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<GetUsersResult> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.get(
      `/assignment`,
      data.params,
      {
        headers: data.headers,
        params: data.params,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }
  /**
   * ANCHOR Gets a list of assignment.
   */
  async list(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<any> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.post(
      `/assignmentlist`,
      data.body,
      {
        headers: data.headers,
        params: data.params,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.data }
    } catch {
      throw new Error('bad-data')
    }
  }

  /**
   * ANCHOR Delete
   */
  async deleteAssignment(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<any> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/assignment`,
      data.params,
      {
        headers: data.headers,
        data: data.body,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.data }
    } catch {
      throw new Error('bad-data')
    }
  }
  /**
   * ANCHOR Gets a list of user.
   */
  async userList(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<GetUsersResult> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.post(
      `/assignment/user`,
      data.body,
      {
        headers: data.headers,
        params: data.params,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }

  /**
   * ANCHOR Gets a list of assignment.
   */
  async add(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<any> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.post(
      `/assignment`,
      data.body,
      {
        headers: data.headers,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.data }
    } catch {
      throw new Error('bad-data')
    }
  }
}
