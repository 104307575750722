import React from 'react'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ProColumns, TableDropdown } from '@ant-design/pro-components'
import { Button, Tag } from 'antd'

import { updateFormTitle } from 'configs/user-management/user-form-title'
import Flag from 'react-world-flags'
import Customer from 'types/customer.types'

export const customerColumns = (
  countries: any,
  activeTab: string,
  setCurrentPartnerData: any,
  setDrawerPartnerVisible: any,
  setDrawerTitle: any,
  deleteCustomer: any,
) => {
  const columns: ProColumns<Customer>[] = [
    {
      dataIndex: 'customer_id',
      key: 'id',
      title: 'ID',
      search: false,
    },
    {
      dataIndex: 'customer_name',
      key: 'customer_name',
      title: 'Customer Name',
    },
    {
      title: 'Country',
      key: 'country_name',
      dataIndex: 'country_name',
      valueType: 'select',
      valueEnum: countries,
      width: 200,
      render: (_, record) => {
        const countries = require('i18n-iso-countries')
        countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
        const iso = countries.getAlpha2Code(record.country_name, 'en')
        return (
          <>
            <Flag
              height={15}
              code={iso}
              style={{ border: '1px solid #eeeeee' }}
            />
            <span style={{ marginLeft: 10 }}>{record.country_name}</span>
          </>
        )
      },
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: 'Fax',
      key: 'fax',
      dataIndex: 'fax',
    },
    {
      title: 'Action',
      valueType: 'option',
      key: 'option',
      width: 50,
      render: (_text, record, _, _action) => [
        <Button size="small">
          <TableDropdown
            key="actionGroup"
            onSelect={(key) => {
              if (key == 'edit') {
                setCurrentPartnerData(record)
                setDrawerPartnerVisible(true)
                setDrawerTitle(updateFormTitle[activeTab])
              } else if (key == 'delete') {
                deleteCustomer(record)
              }
            }}
            menus={[
              { key: 'edit', name: 'Edit', icon: <EditOutlined /> },
              { key: 'delete', name: 'Delete', icon: <DeleteOutlined /> },
            ]}
          />
        </Button>,
      ],
    },
  ]
  return columns
}
