import React, { useEffect, useRef, useState } from 'react'

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  ActionType,
  PageContainer,
  ProCard,
  ProTable,
} from '@ant-design/pro-components'
import { Popconfirm, Space, Table } from 'antd'

import SKBreadcrumb from 'components/sk-breadcumb'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import Flag from 'react-world-flags'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { ShipmentApi } from 'services/shipment-api'

interface EditSharingProps {
  location: any
}

const EditSharing: React.FC<EditSharingProps> = ({ location }) => {
  // const { state = {} } = location
  const [userData] = useRecoilState(userAtom)
  const [currentSharedItems, setCurrentSharedItems] = useState<any>([])
  const [currentShipmentItems, setCurrentShipmentItems] = useState<any>()
  const [currentFileId, setCurrentFileId] = useState()
  const [urlParams, setUrlParams] = useState('')
  const [item_id, setItemId] = useState<number>()
  const shipmentAction = useRef<ActionType>()
  const sharedItemAction = useRef<ActionType>()

  const shipmentMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.shipmentAddShareByFileList(data)
  })

  const shipmentAddShareMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.shipmentAddShareList(data)
  })

  const editSharingMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.editShare(data)
  })

  useEffect(() => {
    const pageParams = new URLSearchParams(location.search)
    const po_number = pageParams.get('po_number')
    const item_no = pageParams.get('item_no')
    const shipment_file_id = pageParams.get('shipment_file_id')
    setCurrentFileId(shipment_file_id)
    setUrlParams(`po_number=${po_number}&item_no=${item_no}`)
  }, [])

  useEffect(() => {
    if (
      !editSharingMutation.isError &&
      editSharingMutation.data?.kind == 'ok'
    ) {
      shipmentAction.current?.reload()
      sharedItemAction.current?.reload()
    }
  }, [editSharingMutation.isError, editSharingMutation.data])

  const request = async (params: any = {}, _sort = {}, _filter = {}) => {
    // console.log(params)
    if (Object.keys(params).length == 0) {
      const pageParams = new URLSearchParams(location.search)
      const po_number = pageParams.get('po_number')
      const item_no = pageParams.get('item_no')
      const shipment_file_id = pageParams.get('shipment_file_id')
      params = { po_number, item_no, shipment_file_id }
    }
    console.log(params)
    // console.log(sort, filter)
    // const filters = Object.keys(params)
    //   .filter((e) => e == 'po_number' || e == 'item_no')
    //   .map((e) => {
    //     return {
    //       fields: e,
    //       value: params[e],
    //       type: 'string',
    //     }
    //   })

    const result = await shipmentMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        // where: [...filters],
      },
      params: {
        // limit: 8,
        // offset: 0,
        shipment_file_id: params.shipment_file_id,
      },
    })

    if (result.kind == 'ok' && Object.keys(result.data).length) {
      setCurrentSharedItems(result.data)
    } else {
      setCurrentSharedItems({})
    }

    return [] //result.data
  }

  const requestShipmentAddShareList = async (
    params: any = {},
    _sort = {},
    _filter = {},
  ) => {
    console.log(params)
    // console.log(sort, filter)
    const filters = Object.keys(params)
      .filter((e) => e == 'po_number' || e == 'item_no')
      .map((e) => {
        return {
          fields: e,
          value: params[e],
          type: 'string',
        }
      })

    const result = await shipmentAddShareMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: [...filters],
      },
      params: {
        limit: 1000,
        offset: 0,
        // shipment_file_id: currentFileId,
      },
    })

    if (result.kind == 'ok') {
      // console.log(result.data)
      setCurrentShipmentItems(result.data)
    }

    return [] //result.data
  }

  const addShare = async (params: {
    shipment_file_id: number | string
    item_id: number[] | string[]
    inspection_id: number | string
  }) => {
    console.log(params)
    const { shipment_file_id, item_id, inspection_id } = params

    const result = await editSharingMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        shipment_file_id: shipment_file_id,
        item_id: item_id,
        inspection_id: inspection_id,
        option: 'add_share',
      },
      // params: {
      //   limit: 1000,
      //   offset: 0,
      // },
    })

    if (result.kind == 'ok') {
      console.log(result.data)
      // setCurrentShipmentItems(result.data)
    }

    return [] //result.data
  }

  const removeShare = async (params: {
    shipment_id: number[]
    item_id: number
  }) => {
    console.log(params)
    const { shipment_id, item_id } = params

    const result = await editSharingMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        shipment_id: shipment_id,
        item_id: item_id,
        option: 'remove_share',
      },
      // params: {
      //   limit: 1000,
      //   offset: 0,
      // },
    })

    if (result.kind == 'ok') {
      console.log(result.data)
      // setCurrentShipmentItems(result.data)
    }

    return [] //result.data
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumbRender(props, defaultDom) {
          return <SKBreadcrumb routes={props?.breadcrumb?.routes || []} />
        },
        breadcrumb: {
          routes: [
            {
              path: '/shipment-document',
              breadcrumbName: 'Shipment Document',
            },
            {
              path: '/file-viewer?' + urlParams,
              breadcrumbName: 'View Files',
            },
            {
              path: '',
              breadcrumbName: 'File Sharing',
            },
          ],
        },
      }}
    >
      <ProCard
        split={'vertical'}
        title={currentSharedItems?.filename || '-'}
        headerBordered
      >
        <ProCard size="small">
          <ProTable<any>
            headerTitle="Search Items to share"
            actionRef={shipmentAction}
            // ANCHOR Columns
            columns={[
              {
                title: '#',
                key: 'index',
                valueType: 'index',
              },
              {
                title: 'SKU',
                key: 'item_no',
                dataIndex: 'item_no',
              },
              {
                title: 'P.O. No.',
                key: 'po_number',
                dataIndex: 'po_number',
              },
              {
                title: 'Country',
                key: 'country_name',
                dataIndex: 'country_name',
                search: false,

                render: (_, record) => {
                  const countries_iso = require('i18n-iso-countries')
                  countries_iso.registerLocale(
                    require('i18n-iso-countries/langs/en.json'),
                  )
                  const iso = countries_iso.getAlpha2Code(
                    record.country_name,
                    'en',
                  )
                  return (
                    <>
                      <Flag
                        height={15}
                        code={iso}
                        style={{ border: '1px solid #eeeeee' }}
                      />
                      <span style={{ marginLeft: 10 }}>
                        {record.country_name}
                      </span>
                    </>
                  )
                },
              },
              {
                title: 'Vendor',
                key: 'supplier_name',
                dataIndex: 'supplier_name',
                search: false,
                render: (_, record) => {
                  let str = record.supplier_name
                  const n = 12
                  return (
                    <span title={str}>
                      {str.length > n ? str.substring(0, n - 1) + '...' : str}
                    </span>
                  )
                },
              },
              {
                title: 'Actions',
                valueType: 'option',
                key: 'option',
                // width: 80,
                render: (dom, entity, index, action, schema) => {
                  // console.log(entity)
                  const pageParams = new URLSearchParams(location.search)
                  const shipment_file_id = pageParams.get('shipment_file_id')

                  return [
                    currentSharedItems?.list_share_item.some(
                      (e: any) =>
                        e.po_number == entity.po_number &&
                        e.item_no == entity.item_no,
                    ) ? (
                      <Space>
                        <CheckOutlined style={{ color: 'gray' }} />
                        <span style={{ color: 'gray' }}>{'Shared'}</span>
                      </Space>
                    ) : (
                      <a
                        href="#"
                        onClick={() => {
                          if (shipment_file_id) {
                            addShare({
                              shipment_file_id: parseInt(shipment_file_id),
                              item_id: [entity.item_id],
                              inspection_id: entity.inspection_id,
                            })
                          }
                        }}
                      >
                        <Space>
                          <PlusOutlined style={{ color: 'green' }} />
                          <span style={{ color: 'green' }}>{'Share'}</span>
                        </Space>
                      </a>
                    ),
                  ]
                },
              },
            ]}
            // request={(params, sorter, filter) => {
            //   // 表单搜索项会从 params 传入，传递给后端接口。
            //   console.log(params, sorter, filter);
            //   return Promise.resolve({
            //     data: ipListDataSource,
            //     success: true,
            //   });
            // }}
            request={requestShipmentAddShareList}
            rowKey="shipment_id"
            options={false}
            pagination={{ pageSize: 6 }}
            search={{
              filterType: 'light',
            }}
            // search={false}
            dataSource={currentShipmentItems}
          />
        </ProCard>
        <ProCard size="small">
          {/* ANCHOR Shared */}
          <ProTable<any>
            headerTitle="Shared items"
            actionRef={sharedItemAction}
            // ANCHOR Columns
            columns={[
              {
                title: '#',
                key: 'index',
                valueType: 'index',
              },
              {
                title: 'Item No.',
                key: 'item_no',
                dataIndex: 'item_no',
                // width: 80,
              },
              {
                title: 'P.O. No.',
                key: 'po_number',
                dataIndex: 'po_number',
              },
              {
                title: 'Country',
                key: 'country_name',
                dataIndex: 'country_name',
                // width: 120,
                render: (_, record) => {
                  const countries_iso = require('i18n-iso-countries')
                  countries_iso.registerLocale(
                    require('i18n-iso-countries/langs/en.json'),
                  )
                  const iso = countries_iso.getAlpha2Code(
                    record.country_name,
                    'en',
                  )
                  return (
                    <>
                      <Flag
                        height={15}
                        code={iso}
                        style={{ border: '1px solid #eeeeee' }}
                      />
                      <span style={{ marginLeft: 10 }}>
                        {record.country_name}
                      </span>
                    </>
                  )
                },
              },
              {
                title: 'Vendor',
                key: 'supplier_name',
                dataIndex: 'supplier_name',
                render: (_, record) => {
                  let str = record.supplier_name
                  const n = 12
                  return (
                    <span title={str}>
                      {str.length > n ? str.substring(0, n - 1) + '...' : str}
                    </span>
                  )
                },
              },
              {
                title: 'Actions',
                valueType: 'option',
                key: 'option',
                // width: 80,
                render: (dom, entity, index, action, schema) => {
                  // console.log(entity)

                  return [
                    <Popconfirm
                      title="Are you sure to remove this item?"
                      onConfirm={() => {
                        // if (item_id) {
                        removeShare({
                          shipment_id: [entity.shipment_id],
                          item_id: entity.item_id,
                        })
                        // }
                      }}
                    >
                      <a href="#" style={{ color: 'red' }}>
                        <Space>
                          <CloseOutlined />
                          {'Remove Share'}
                        </Space>
                      </a>
                    </Popconfirm>,
                  ]
                },
              },
            ]}
            // request={(params, sorter, filter) => {
            //   // 表单搜索项会从 params 传入，传递给后端接口。
            //   console.log(params, sorter, filter);
            //   return Promise.resolve({
            //     data: ipListDataSource,
            //     success: true,
            //   });
            // }}
            request={request}
            rowKey="shipment_id"
            options={false}
            pagination={false}
            search={false}
            dataSource={currentSharedItems?.list_share_item || []}
          />
        </ProCard>
      </ProCard>
    </PageContainer>
  )
}

export default EditSharing
