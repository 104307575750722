import * as React from 'react'

import type { ProColumns } from '@ant-design/pro-components'

import _ from 'lodash'

import { Monthly } from '../types/monthly.types'

export const columnsMonthlyClient: ProColumns<Monthly>[] = [
  // {
  //   dataIndex: 'index',
  //   valueType: 'index',
  //   width: 48,
  //   // disable: true,
  //   fixed: 'left',
  //   title: '#',
  // },
  {
    title: 'Client',
    width: 150,
    dataIndex: 'client_name',
    // disable: true,
    fixed: 'left',
  },
  {
    title: 'Jan',
    dataIndex: 'jan',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["1"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Feb',
    dataIndex: 'feb',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["2"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Mar',
    dataIndex: 'mar',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["3"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Apr',
    dataIndex: 'apr',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["4"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'May',
    dataIndex: 'may',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["5"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Jun',
    dataIndex: 'jun',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["6"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Jul',
    dataIndex: 'jul',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["7"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Aug',
    dataIndex: 'aug',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["8"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Sep',
    dataIndex: 'sep',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["9"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Oct',
    dataIndex: 'oct',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["10"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Nov',
    dataIndex: 'nov',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["11"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },
  {
    title: 'Dec',
    dataIndex: 'dec',
    hideInSearch: true,
    width: 80,
    render(dom, entity, index, action, schema) {
      let value = _.get(entity, 'summary["12"][0].total_amount', '-')
      value = isNaN(value)
        ? value
        : parseFloat(value.toFixed(2)).toLocaleString()
      return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>
    },
  },

  {
    title: 'Total Volume',
    dataIndex: 'total_volume',
    hideInSearch: true,
    width: 120,
    // hideInTable: true,
    fixed: 'right',
    render(dom, entity, index, action, schema) {
      let sum = 0
      const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      for (const iterator of month) {
        sum += parseFloat(
          _.get(entity, `summary['${iterator}'][0].total_amount`, 0),
        )
      }
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {parseFloat(sum.toFixed(2)).toLocaleString()}
        </div>
      )
    },
  },
  // {
  //   title: 'Total Volume(%)',
  //   dataIndex: 'total_volume_per',
  //   hideInSearch: true,
  //   // hideInTable: true,
  // },
  // {
  //   title: 'Product Category',
  //   dataIndex: 'product_category',
  //   hideInSearch: true,
  //   // hideInTable: true,
  // },
  // {
  //   hideInTable: true,
  //   title: 'All Status',
  //   dataIndex: 'all_status',
  //   valueType: 'select',
  //   valueEnum: {
  //     all: { text: 'All Status' },
  //     fail: { text: 'Fail' },
  //     pass: { text: 'Pass' },
  //     on_hold: { text: 'On Hold' },
  //   },
  // },
]
