import React, { useEffect } from 'react'

import {
  DownOutlined,
  EditOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Avatar, Dropdown, Menu, MenuProps, Spin } from 'antd'

import { navigate } from 'gatsby'
import { LogoutParams } from 'models/user/login'
import { initialState, userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { UserApi } from 'services/user-api'

const AvatarDropdown = () => {
  const [userData, setUserData] = useRecoilState(userAtom)

  const logoutMutation = useMutation(async (data: LogoutParams) => {
    const userApi = useApi(UserApi)
    return userApi.logout(data)
  })

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key == 'logout') {
      logoutMutation.mutate({ token: userData.token })
    } else if (e.key == 'edit-profile') {
      navigate('/user-management/edit-profile')
    }
  }

  useEffect(() => {
    const logoutResult = logoutMutation.data
    if (logoutResult?.kind == 'ok') {
      setUserData(initialState)
    }
  }, [logoutMutation.data, logoutMutation.isError])

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Edit profile',
          key: 'edit-profile',
          icon: <EditOutlined />,
        },
        {
          type: 'divider',
        },
        {
          label: 'Logout',
          key: 'logout',
          icon: <LogoutOutlined />,
        },
      ]}
    />
  )

  return (
    <Dropdown overlay={menu}>
      <a
        onClick={(e) => e.preventDefault()}
        style={{
          // width: 200,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Avatar
          icon={<UserOutlined />}
          style={{ backgroundColor: '#001529' }}
          size={'default'}
        />

        <div
          style={{
            // marginLeft: 0,
            padding: '0 10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            height: 40,
          }}
        >
          <div
            style={{
              height: 18,
              // textAlign: 'center',
              lineHeight: '18px',
              fontWeight: 'bold',
            }}
          >
            {userData.profile_name}
          </div>
          <div
            style={{
              height: 18,
              // textAlign: 'center',
              lineHeight: '18px',
              fontWeight: 'lighter',
            }}
          >
            {userData.country_name}
          </div>
        </div>
        {logoutMutation.isLoading ? (
          <>
            {' '}
            <Spin size="small" />
          </>
        ) : (
          ''
        )}

        <DownOutlined style={{ marginLeft: 5, color: '#001529' }} />
      </a>
    </Dropdown>
  )
}

export default AvatarDropdown
