export const generateSummaryOfOrderFilter = (params: any) => {
  const { po_number, client, vendor } = params
  let where = []

  if (po_number) {
    where.push({
      fields: 'po_number',
      value: po_number,
      type: 'string',
    })
  }

  if (client) {
    where.push({
      fields: 'client',
      value: client,
      type: 'string',
    })
  }

  if (vendor) {
    where.push({
      fields: 'vendor',
      value: vendor,
      type: 'string',
    })
  }

  return where
}
