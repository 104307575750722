import { ProFormColumnsType } from '@ant-design/pro-form'

export type DataItem = {
  name: string
  state: string
}

export const resetPasswordFields: ProFormColumnsType<DataItem>[] = [
  {
    title: 'Current Password',
    dataIndex: 'password',
    valueType: 'password',
    formItemProps: {
      rules: [
        {
          required: true,
        },
      ],
    },
    fieldProps: { size: 'default', autoComplete: 'false' },
    width: 'md',
  },
  {
    title: 'New Password',
    dataIndex: 'new_password',
    valueType: 'password',
    formItemProps: {
      rules: [
        {
          required: true,
        },
      ],
    },
    fieldProps: { size: 'default', autoComplete: 'false' },
    width: 'md',
  },
  {
    title: 'Re-New Password',
    dataIndex: 'renew_password',
    valueType: 'password',
    formItemProps: {
      rules: [
        {
          required: true,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('new_password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error('The two passwords that you entered do not match!'),
            )
          },
        }),
      ],
    },
    fieldProps: { size: 'default', autoComplete: 'false' },
    width: 'md',
  },
]
