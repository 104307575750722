export const pageTab = [
  {
    tab: 'Users',
    key: 'users',
  },
  {
    tab: 'Customers',
    key: 'customers',
  },
  {
    tab: 'Suppliers',
    key: 'suppliers',
  },
]
