import React from 'react'

import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons'
import { ProColumns, TableDropdown } from '@ant-design/pro-components'
import { Avatar, Button, Tag } from 'antd'

import { updateFormTitle } from 'configs/user-management/user-form-title'
import Flag from 'react-world-flags'
import { User } from 'user/user'

export const userColumns = (
  permissions: any,
  countries: any,
  activeTab: string,
  setCurrentData: any,
  setDrawerVisible: any,
  setDrawerTitle: any,
  deleteUser: any,
) => {
  const columns: ProColumns<User>[] = [
    {
      dataIndex: 'user_id',
      valueType: 'digit',
      title: 'ID',
      search: false,
    },
    {
      dataIndex: 'profile_name',
      title: 'Profile Name',
      valueType: 'text',
      render: (_, record) => {
        return (
          <>
            <Avatar
              size={28}
              style={{ marginRight: 15 }}
              icon={<UserOutlined />}
            />
            {record.profile_name}
          </>
        )
      },
    },
    {
      dataIndex: 'username',
      title: 'Username',
      hideInSearch: true,
      valueType: 'text',
    },
    {
      title: 'Permission',
      dataIndex: 'role_name',
      valueType: 'select',
      valueEnum: permissions,
    },
    {
      title: 'Email',
      hideInSearch: true,
      dataIndex: 'email',
      valueType: 'text',
    },
    {
      title: 'Country',
      dataIndex: 'country_name',
      valueType: 'select',
      valueEnum: countries,
      render: (_, record) => {
        const countries = require('i18n-iso-countries')
        countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
        const iso = countries.getAlpha2Code(record.country_name, 'en')
        return (
          <>
            <Flag
              height={15}
              code={iso}
              style={{ border: '1px solid #eeeeee' }}
            />
            <span style={{ marginLeft: 10 }}>{record.country_name}</span>
          </>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      valueType: 'select',
      valueEnum: {
        A: { text: 'Active', status: 'success' },
        I: { text: 'Inactive', status: 'error' },
      },
      render: (_, record) => {
        return (
          <Tag color={record.status == 'A' ? 'green' : 'red'}>
            {record.status == 'A' ? 'Active' : 'Inactive'}
          </Tag>
        )
      },
    },
    {
      title: 'Action',
      valueType: 'option',
      key: 'option',
      width: 50,
      render: (_text, record, _, _action) => [
        <Button size="small">
          <TableDropdown
            key="actionGroup"
            onSelect={(key) => {
              if (key == 'edit') {
                setCurrentData(record)
                setDrawerVisible(true)
                setDrawerTitle(updateFormTitle[activeTab])
              } else if (key == 'delete') {
                deleteUser(record)
              }
            }}
            menus={[
              { key: 'edit', name: 'Edit', icon: <EditOutlined /> },
              { key: 'delete', name: 'Delete', icon: <DeleteOutlined /> },
            ]}
          />
        </Button>,
      ],
    },
  ]
  return columns
}
