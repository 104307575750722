import { ApiResponse } from 'apisauce'
import { DropdownParams } from 'models/master-data/dropdown'
import { Api } from 'services/api-integrations/api'
import { getGeneralApiProblem } from 'services/api-integrations/api-problem'

const API_PAGE_SIZE = 50

export class MasterDataApi extends Api {
  async dropdown(params: DropdownParams) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/dropdown`,
      params.params,
      {
        headers: params.headers,
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) throw new Error(problem.kind)
    }

    // transform the data into the format we are expecting
    try {
      const result = response.data
      return { kind: 'ok', data: result }
    } catch {
      return { kind: 'bad-data' }
    }
  }
}
