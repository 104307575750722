import { ApiResponse } from 'apisauce'
import { LoginParams, LogoutParams } from 'models/user/login'
import { UpdateProfileParams } from 'models/user/user'
import { Api } from 'services/api-integrations/api'
import { getGeneralApiProblem } from 'services/api-integrations/api-problem'
import { DiskSizeParam } from 'user/menu'

import * as Types from './api-integrations/api.types'

const API_PAGE_SIZE = 50

export class UserApi extends Api {
  /**
   * ANCHOR Login
   */
  async login(params: LoginParams) {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/login`,
      params,
    )
    console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) throw new Error(problem.kind)
    }

    // transform the data into the format we are expecting
    try {
      const rawUsers = response.data
      return { kind: 'ok', data: rawUsers?.result }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * ANCHOR Logout
   */
  async logout(params: LogoutParams) {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/logout`,
      null,
      {
        headers: {
          'x-access-token': params.token,
        },
      },
    )
    console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) throw new Error(problem.kind)
    }

    // transform the data into the format we are expecting
    try {
      const rawUsers = response.data
      return { kind: 'ok', data: rawUsers }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * ANCHOR get disk size
   */
  async getDiskSize(params: DiskSizeParam) {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/disksize`,
      undefined,
      {
        headers: {
          'x-access-token': params.token,
        },
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) throw new Error(problem.kind)
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      return { kind: 'bad-data' }
    }
  }

  /**
   * ANCHOR Update profile
   */
  async updateProfile(params: UpdateProfileParams) {
    // const [userData] = useRecoilState(userAtom)
    const headers = params.headers

    const response: ApiResponse<any> = await this.apisauce.put(
      `/editprofile`,
      params.payload,
      {
        headers: {
          'x-access-token': headers.token,
        },
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        throw new Error(`${response.data?.message}`)
      }
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }

  /**
   * ANCHOR Create User
   */
  async createUser(params: UpdateProfileParams) {
    // const [userData] = useRecoilState(userAtom)
    const headers = params.headers

    const response: ApiResponse<any> = await this.apisauce.post(
      `/register`,
      params.payload,
      {
        headers: {
          'x-access-token': headers.token,
        },
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        throw new Error(`${response.data?.message}`)
      }
    }
    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }
  /**
   * ANCHOR Gets a list of users.
   */
  async getUsers(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<Types.GetUsersResult> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.post(
      `/listuser`,
      data.body,
      {
        headers: data.headers,
        params: data.params,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }

  /**
   * ANCHOR Delete user.
   */
  async deleteUser(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<Types.GetUsersResult> {
    // make the api call
    console.log(data)
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/deleteprofile`,
      data.params,
      {
        headers: data.headers,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }
}
