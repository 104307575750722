import * as React from 'react'

import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  RetweetOutlined,
} from '@ant-design/icons'
import type { ProColumns } from '@ant-design/pro-components'
import { TableDropdown } from '@ant-design/pro-components'
import { Button, Dropdown, Menu, Modal, Space, Tag, message } from 'antd'

import { AqlInspectionListResult } from 'aql-inspection/aql-inspection'
import { navigate } from 'gatsby'
import moment from 'moment'
import Flag from 'react-world-flags'

const { confirm } = Modal

export const aqlColumns = (
  countries: any = [],
  currentPage: number,
  pageSize: number,
  onDelete: any,
  onRestore: any,
  onPermanentDelete: any,
  activeTab: string,
  download: any,
  actionList: any,
  onCopyPo: any,
) => {
  const columns: ProColumns<AqlInspectionListResult>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      valueType: 'index',
      width: 48,
      fixed: 'left',
      hideInSearch: true,
      render(dom, entity, index, action, schema) {
        return index + 1 + (currentPage - 1) * pageSize
      },
    },
    {
      title: 'Item No.',
      key: 'item_no',
      dataIndex: 'item_no',
    },
    {
      title: 'P.O. No.',
      width: 150,
      dataIndex: 'po_number',
      fixed: 'left',
    },
    {
      title: 'By',
      dataIndex: 'profile_name',
      width: 120,
      render: (_, record) => {
        return record.profile_name ? record.profile_name : '-'
      },
    },
    {
      disable: true,
      title: 'Country',
      dataIndex: 'country',
      width: 150,
      valueEnum: countries,
      valueType: 'select',
      render: (_, record) => {
        const countries_iso = require('i18n-iso-countries')
        countries_iso.registerLocale(
          require('i18n-iso-countries/langs/en.json'),
        )
        const iso = countries_iso.getAlpha2Code(record.country, 'en')
        return (
          <>
            <Flag
              height={15}
              code={iso}
              style={{ border: '1px solid #eeeeee' }}
            />
            <span style={{ marginLeft: 10 }}>{record.country}</span>
          </>
        )
      },
    },
    {
      disable: true,
      title: 'Client',
      dataIndex: 'customer_name',
      render: (_, record) => {
        let str = record.customer_name
        const n = 18
        return str.length > n ? str.substring(0, n - 1) + '...' : str
      },
      width: 120,
      filters: true,
    },
    {
      disable: true,
      title: 'Vendor',
      dataIndex: 'supplier_name',
      render: (_, record) => {
        let str = record.supplier_name
        const n = 18
        return str.length > n ? str.substring(0, n - 1) + '...' : str
      },
      width: 120,
      filters: true,
    },
    {
      disable: true,
      title: 'Factory',
      dataIndex: 'factory',
      render: (_, record) => {
        let str = record.factory
        const n = 18
        return str.length > n ? str.substring(0, n - 1) + '...' : str
      },
      width: 120,
      filters: true,
    },
    {
      title: 'ETD Date',
      key: 'etd_date',
      dataIndex: 'etd_date',
      valueType: 'date',
      // sorter: true,
      width: 120,
      render: (_, record) => {
        return record.etd_date ? moment(record.etd_date).format('ll') : '-'
      },
    },
    {
      title: 'Inspection Date Inline',
      key: 'inspection_date_inline',
      dataIndex: 'inspection_date_inline',
      valueType: 'date',
      // sorter: true,
      width: 120,
      render: (_, record) => {
        return record.inspection_date_inline
          ? moment(record.inspection_date_inline).format('ll')
          : '-'
      },
    },
    {
      title: 'Inspection Date Final',
      key: 'inspection_date_final',
      dataIndex: 'inspection_date_final',
      valueType: 'date',
      // sorter: true,
      width: 120,
      render: (_, record) => {
        return record.inspection_date_final
          ? moment(record.inspection_date_final).format('ll')
          : '-'
      },
    },
    {
      title: 'Inspection Date Re-Final',
      key: 'inspection_date_refinal',
      dataIndex: 'inspection_date_refinal',
      valueType: 'date',
      // sorter: true,
      width: 140,
      render: (_, record) => {
        const dt =
          record.inspection_date_refinal3 ||
          record.inspection_date_refinal2 ||
          record.inspection_date_refinal
        return dt ? moment(dt).format('ll') : '-'
      },
    },
    {
      title: 'Inspection Result',
      dataIndex: 'active_status_name',
      fixed: 'left',
      valueType: 'select',
      valueEnum: { fail: 'FAIL', pass: 'PASS', 'on hold': 'On Hold' },
      width: 90,
      render: (_, record) => {
        return (
          <Tag
            color={
              record.active_status_name == 'FAIL'
                ? 'red'
                : record.active_status_name == 'On Hold'
                ? 'orange'
                : 'green'
            }
          >
            {record.active_status_name != 'PASS'
              ? record.active_status_name
              : record.report_status_name == 'INLINE'
              ? 'PASS-IN'
              : record.report_status_name == 'FINAL'
              ? 'PASS-FN'
              : 'PASS-RFN'}
          </Tag>
        )
      },
    },
    {
      title: 'SKU #',
      width: 150,
      dataIndex: 'item_no',
      fixed: 'left',
    },
    // {
    //   // disable: true,
    //   title: 'Inspection Report by SKU #',
    //   // key: 'item_no',
    //   width: 120,
    //   render: (_, record) => {
    //     return record.item_no ? record.item_no : '-'
    //   },
    // },
    // {
    //   title: 'Report',
    //   valueType: 'option',
    //   key: 'report',
    //   width: 90,
    //   fixed: 'right',
    //   render: (_text, record, _, _action) => [
    //     <Dropdown
    //       overlay={
    //         <Menu
    //           onClick={async (e) => {
    //             try {
    //               await download(record, e.key)
    //             } catch (error) {
    //               message.error('Can not download this report.')
    //             }
    //           }}
    //           // onSelect={(key) => download(record, key)}
    //           items={[
    //             {
    //               label: 'Inline',
    //               key: 'inline',
    //               icon: <CopyOutlined />,
    //             },
    //             {
    //               label: 'Final',
    //               key: 'final',
    //               icon: <CopyOutlined />,
    //             },
    //             {
    //               label: 'Re-Final',
    //               key: 're-final',
    //               icon: <CopyOutlined />,
    //             },
    //           ]}
    //         />
    //       }
    //     >
    //       <a onClick={(e) => e.preventDefault()}>
    //         <Space>
    //           Report
    //           <DownOutlined />
    //         </Space>
    //       </a>
    //     </Dropdown>,
    //   ],
    // },
    {
      title: 'Action',
      valueType: 'option',
      key: 'option',
      width: 70,
      fixed: 'right',

      render: (_text, record, _, _action) => [
        <Button size="small">
          <TableDropdown
            key="actionGroup"
            onSelect={(key) => {
              if (key == 'edit') {
                navigate('/aql-inspection/view?id=' + record.id)
              } else if (key == 'copy') {
                onCopyPo(record)
              } else if (key == 'delete') {
                // deleteUser(record)
                onDelete(record.id)
              } else if (key == 'restore') {
                onRestore(record.id)
              } else if (key == 'permanent_delete') {
                onPermanentDelete(record.id)
              }
            }}
            menus={[
              {
                key: 'edit',
                name: actionList.includes('edit') ? 'View & Edit' : 'View',
                icon: actionList.includes('edit') ? (
                  <EditOutlined />
                ) : (
                  <EyeOutlined />
                ),
                hidden: activeTab != 'all_tab',
              },
              {
                key: 'copy',
                name: 'Copy PO.',
                icon: <CopyOutlined />,
                disabled: !actionList.includes('insert'),
                hidden: activeTab != 'all_tab',
              },
              {
                key: 'delete',
                name: (
                  <span
                    style={{
                      color: !actionList.includes('delete')
                        ? 'lightgray'
                        : 'red',
                    }}
                  >
                    Delete
                  </span>
                ),
                icon: (
                  <span
                    style={{
                      color: !actionList.includes('delete')
                        ? 'lightgray'
                        : 'red',
                    }}
                  >
                    <DeleteOutlined />
                  </span>
                ),
                disabled: !actionList.includes('delete'),
                hidden: activeTab != 'all_tab',
              },
              {
                key: 'restore',
                name: 'Restore',
                icon: <RetweetOutlined />,
                disabled: !actionList.includes('delete'),
                hidden: activeTab != 'trash_tab',
              },
              {
                key: 'permanent_delete',
                name: 'Permanently Delete',
                icon: <DeleteOutlined />,
                disabled: !actionList.includes('delete'),
                hidden: activeTab != 'trash_tab',
              },
            ]}
          />
        </Button>,
      ],
    },
  ]
  return columns
}
