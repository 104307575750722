import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()
// ANCHOR User State

// export const customDropdown = atom({
//   key: 'customerDropdownState',
//   default: [],
//   // effects_UNSTABLE: [persistAtom]
// })

export const countryAtom = atom({
  key: 'countryState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const roleState = atom({
  key: 'roleState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const supplierState = atom({
  key: 'supplierState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const customerState = atom({
  key: 'customerState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const customerFilterState = atom({
  key: 'customerFilterState',
  default: 0,
})

export const filteredCustomerState = selector({
  key: 'filteredCustomerState',
  get: ({ get }) => {
    const customerList = get(customerState)
    const customer_id = get(customerFilterState)
    if (customer_id != 0) {
      return customerList.filter((e: any) => e.customer_id == customer_id)
    } else {
      return {}
    }
  },
})

export const inspectionTypeState = atom({
  key: 'inspectionTypeState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const inspectionStandardState = atom({
  key: 'inspectionStandardState',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

// export const employeeRoleState = selector({
//   key: 'employeeRoleState',
//   get: ({ get }) => {
//     const allRole = get(roleState)
//     return allRole.filter((e: any) => e.value != 2 && e.value != 9)
//   },
// })

// export const customerRoleState = selector({
//   key: 'customerRoleState',
//   get: ({ get }) => {
//     const allRole = get(roleState)
//     return allRole.filter((e: any) => e.value == 2)
//   },
// })

// export const supplierRoleState = selector({
//   key: 'supplierRoleState',
//   get: ({ get }) => {
//     const allRole = get(roleState)
//     return allRole.filter((e: any) => e.value == 9)
//   },
// })
