import * as React from 'react'

import { EyeOutlined } from '@ant-design/icons'
import type { ProColumns } from '@ant-design/pro-components'
import { TableDropdown } from '@ant-design/pro-components'
import { Badge, Button, Dropdown, Menu, Space, Tag, Tooltip } from 'antd'

import { Link, navigate } from 'gatsby'
import moment from 'moment'
import Flag from 'react-world-flags'

import SpecSheet from '../types/spec-sheet.types'

export const columnsSpecSheet: ProColumns<SpecSheet>[] = [
  {
    dataIndex: 'index',
    valueType: 'index',
    width: 48,
    disable: true,
  },
  {
    title: 'SKU',
    key: 'item_no',
    dataIndex: 'item_no',
  },
  {
    title: 'P.O No.',
    key: 'po_number',
    dataIndex: 'po_number',
  },
  {
    title: 'Country',
    key: 'country_name',
    dataIndex: 'country_name',
    search: false,
    render: (_, record) => {
      const countries_iso = require('i18n-iso-countries')
      countries_iso.registerLocale(require('i18n-iso-countries/langs/en.json'))
      const iso = countries_iso.getAlpha2Code(record.country_name, 'en')
      return (
        <>
          <Flag
            height={15}
            code={iso}
            style={{ border: '1px solid #eeeeee' }}
          />
          <span style={{ marginLeft: 10 }}>{record.country_name}</span>
        </>
      )
    },
  },
  {
    title: 'Vendor',
    key: 'supplier_name',
    dataIndex: 'supplier_name',
    search: false,
  },
  {
    title: 'Last Updated',
    key: 'upload_date',
    dataIndex: 'upload_date',
    search: false,
    render(dom, entity, index, action, schema) {
      return moment(dom).format('lll')
    },
  },

  {
    title: 'Action',
    valueType: 'option',
    key: 'option',
    width: 80,
    render: (dom, entity, index, action, schema) => {
      // console.log(entity)
      return [
        <Badge
          count={entity.list_specsheet?.length}
          style={{ backgroundColor: 'green' }}
        >
          <Link
            to={`/spec-sheet/file-viewer?po_number=${entity?.po_number}&item_no=${entity?.item_no}`}
            state={entity}
            // type="default"
            // onClick={() =>
            //   navigate('/spec-sheet/file-viewer', { state: entity })
            // }
          >
            <Button>
              <EyeOutlined />
              Files
            </Button>
          </Link>
        </Badge>,
      ]
    },
  },
]
