import { ProFormColumnsType } from '@ant-design/pro-form'

export type DataItem = {
  name: string
  state: string
}

export const profileFields: ProFormColumnsType<DataItem>[] = [
  {
    title: 'Display Name',
    dataIndex: 'profile_name',
  },
  {
    title: 'Country',
    dataIndex: 'country_name',
    editable: false,
  },
]
