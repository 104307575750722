import React, { useEffect } from 'react'

import { navigate } from 'gatsby'
import { userAtom } from 'models/user/user-store'
import { useRecoilState } from 'recoil'

import PrimaryLayout from './primary-layout'
import WhiteLayout from './white-layout'

const ParentLayout: React.FC<{
  location: any
  pageContext: { layout: string }
  custom404: any
  children: any
}> = ({ custom404, children, pageContext, location }) => {
  const [userData] = useRecoilState(userAtom)

  useEffect(() => {
    if (!userData.token) {
      navigate('/auth/login')
    }
  }, [userData.token])

  return pageContext.layout == 'primary' ? (
    <PrimaryLayout pageContext={pageContext} location={location}>
      {children}
    </PrimaryLayout>
  ) : (
    <WhiteLayout
      pageContext={pageContext}
      location={location}
      custom404={custom404}
    >
      {children}
    </WhiteLayout>
  )
}

export default ParentLayout
