export const generateSupplierFilter = (params: any, activeTab: string) => {
  const { supplier_name, country_name, address, phone, fax } = params
  let where = []

  if (supplier_name) {
    where.push({
      fields: 'supplier_name',
      value: supplier_name,
      type: 'string',
    })
  }

  if (phone) {
    where.push({
      fields: 'phone',
      value: phone,
      type: 'string',
    })
  }

  if (fax) {
    where.push({
      fields: 'fax',
      value: fax,
      type: 'string',
    })
  }

  if (address) {
    where.push({
      fields: 'address',
      value: address,
      type: 'string',
    })
  }

  if (country_name && country_name != '1') {
    where.push({
      fields: 'country_id',
      value: parseInt(country_name),
      type: 'int',
    })
  }

  return where
}
