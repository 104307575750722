import React, { useEffect, useState } from 'react'

import {
  PageContainer,
  ProCard,
  ProDescriptions,
  ProForm,
  ProFormDigit,
  ProFormGroup,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components'
import type { ProFormColumnsType } from '@ant-design/pro-components'
import { BetaSchemaForm } from '@ant-design/pro-components'
import { Button, message } from 'antd'

import { Link, navigate } from 'gatsby'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { AqlApi } from 'services/aql-api'

const valueEnum = {
  money: { text: '按金额' },
  discount: { text: '按折扣' },
}

type DataItem = {
  name: string
  state: string
}

let columns: ProFormColumnsType[] = [
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Material',
        dataIndex: 'defect_material',
        valueType: 'digit',
        name: 'defect_material',
        fieldProps: {
          placeholder: 'Q.T.',
          // width: 200,
        },
        formItemProps: {
          // labelCol: { span: 9 },
          // wrapperCol: { span: 15 },
        },
      },
      {
        title: '',
        dataIndex: 'defect_material_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Color',
        dataIndex: 'defect_color',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
          // width: 200,
        },
      },
      {
        title: '',
        dataIndex: 'defect_color_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Design',
        dataIndex: 'defect_design',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
          // width: 200,
        },
      },
      {
        title: '',
        dataIndex: 'defect_design_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Sharp Point / Rough Edge',
        dataIndex: 'defect_sharppoint',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_sharppoint_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Measurement (Size)',
        dataIndex: 'defect_measurement',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_measurement_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Finish',
        dataIndex: 'defect_finish',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_finish_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Strength',
        dataIndex: 'defect_strength',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_strength_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Durability',
        dataIndex: 'defect_durability',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_durability_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Painting / Polishing / Platings',
        dataIndex: 'defect_painting',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_painting_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Scratch',
        dataIndex: 'defect_scratch',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_scratch_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Rust',
        dataIndex: 'defect_rust',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_rust_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Price Label / Barcode Label',
        dataIndex: 'defect_price_barcode_label',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_price_barcode_label_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Shipping Mark',
        dataIndex: 'defect_shippingmark',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_shippingmark_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Carton Quality',
        dataIndex: 'defect_carton',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_carton_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: 'Other',
        dataIndex: 'defect_other',
        valueType: 'digit',
        fieldProps: {
          placeholder: 'Q.T.',
        },
      },
      {
        title: '',
        dataIndex: 'defect_other_desc',
        valueType: 'text',
        fieldProps: {
          placeholder: 'Reasons of defect',
        },
        width: 'lg',
      },
    ],
  },
]

let columnsTypeB: ProFormColumnsType[] = [
  {
    title: '2nd Inspection',
    valueType: 'group',
    columns: [
      {
        title: "Accepted Q'ty after repairing",
        dataIndex: 'accepted_qty',
        valueType: 'digit',
        name: 'accepted_qty',
        fieldProps: {
          placeholder: 'Q.T.',
          // width: 200,
        },
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: "Rejected Q'ty after repairing",
        dataIndex: 'rejected_qty',
        valueType: 'digit',
        name: 'rejected_qty',
        fieldProps: {
          placeholder: 'Q.T.',
          // width: 200,
        },
      },
    ],
  },
  {
    title: 'Final Result',
    valueType: 'group',
    columns: [
      {
        title: "Final Acceptable Q'ty",
        dataIndex: 'accepted_qty_final',
        valueType: 'digit',
        name: 'accepted_qty_final',
        fieldProps: {
          placeholder: 'Q.T.',
          // width: 200,
        },
      },
    ],
  },
  {
    title: '',
    valueType: 'group',
    columns: [
      {
        title: "Final Rejected Q'ty",
        dataIndex: 'rejected_qty_final',
        valueType: 'digit',
        name: 'rejected_qty_final',
        fieldProps: {
          placeholder: 'Q.T.',
          // width: 200,
        },
      },
    ],
  },
]

interface ItemReportFormProps {}

const ItemReportForm: React.FC<ItemReportFormProps> = () => {
  const [isEditMode, setIsEditMode] = useState<boolean | null>(null)
  const [userData] = useRecoilState(userAtom)
  const [currentItemData, setCurrentItemData] = useState<any>({})
  const [inspectionId, setInspectionId] = useState<any>()
  const [currentDefectData, setCurrentDefectData] = useState<any>({})
  const [currentInspectionType, setCurrentInspectionType] = useState<
    string | null
  >(null)

  const itemMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.viewItem(data)
  })

  const addDefectMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.addItemDefect(data)
  })

  const getDefectMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.getItemDefect(data)
  })

  const updateDefectMutation = useMutation(async (data: any) => {
    const api = useApi(AqlApi)
    return api.updateItemDefect(data)
  })

  useEffect(() => {
    const pageParams = new URLSearchParams(location.search)
    const inspection_id = pageParams.get('inspection_id')
    const item_id = pageParams.get('item_id')
    const inspection_type = pageParams.get('inspection_type')
    let report_status = pageParams.get('report_status')
    report_status = report_status?.toUpperCase() || ''

    setInspectionId(inspection_id)
    setCurrentInspectionType(inspection_type)

    itemMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        inspection_id,
        item_id,
      },
    })

    getDefectMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        inspection_id,
        item_id,
        inspection_type,
        report_status_name: report_status,
      },
    })
  }, [])

  useEffect(() => {
    if (!getDefectMutation.isError && getDefectMutation.data) {
      if (
        getDefectMutation.data.kind == 'ok'
        // getDefectMutation.data.data.result.length
      ) {
        const result = getDefectMutation.data.data.result
        console.log(result)
        if (result.length) {
          let data = result[0]
          console.log(data)
          if (data.product_accepted == 'ON HOLD') {
            data.product_accepted = 'ONHOLD'
          }
          console.log(data)
          setCurrentDefectData(data)
          setIsEditMode(true)
        } else {
          setCurrentDefectData({ product_accepted: 'ONHOLD' })
          setIsEditMode(false)
        }
      }
    }
  }, [getDefectMutation.data, getDefectMutation.isError])

  useEffect(() => {
    if (!itemMutation.isError && itemMutation.data) {
      if (
        itemMutation.data.kind == 'ok' &&
        itemMutation.data.data.result.length
      ) {
        const pageParams = new URLSearchParams(location.search)

        const report_status = pageParams.get('report_status')
        const result = itemMutation.data.data.result

        let data = result[0]
        data.type = report_status?.toUpperCase()
        setCurrentItemData(data)
      }
    }
  }, [itemMutation.data, itemMutation.isError])

  useEffect(() => {
    if (!updateDefectMutation.isError && updateDefectMutation.data) {
      if (updateDefectMutation.data.kind == 'ok') {
        message.success('Update Success.')
        navigate(-1)
      }
    }
  }, [updateDefectMutation.data, updateDefectMutation.isError])

  useEffect(() => {
    if (!addDefectMutation.isError && addDefectMutation.data) {
      if (addDefectMutation.data.kind == 'ok') {
        message.success('Adding the data is success.')
        setIsEditMode(true)
        navigate(-1)
      }
    }
  }, [addDefectMutation.data, addDefectMutation.isError])

  useEffect(() => {
    console.log(isEditMode)
  }, [isEditMode])

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumb: {
          itemRender(route, params, routes, paths) {
            if (route.path) {
              return <Link to={route.path}>{route.breadcrumbName}</Link>
            } else {
              return route.breadcrumbName
            }
          },
          routes: [
            {
              path: '/aql-inspection',
              breadcrumbName: 'AQL Inspection',
            },
            {
              path: '/aql-inspection/view?id=' + inspectionId,
              breadcrumbName: 'Inspection Detail',
            },
            {
              path: '',
              breadcrumbName: 'Defects',
            },
          ],
        },
      }}
    >
      <ProCard size="small">
        <ProDescriptions
          // actionRef={actionRef}
          // bordered
          formProps={{
            onValuesChange: (e, f) => console.log(f),
          }}
          title="Item Details"
          // request={async () => {

          // }}
          // editable={{}}
          column={4}
          dataSource={currentItemData}
          columns={[
            {
              title: 'P.O. No.',
              key: 'po_number',
              dataIndex: 'po_number',
              // copyable: true,
              // ellipsis: true,
            },
            {
              title: 'Item No.',
              key: 'item_no',
              dataIndex: 'item_no',
            },
            {
              title: 'Color',
              key: 'color',
              dataIndex: 'color',
            },
            {
              title: 'Description',
              key: 'item_description',
              dataIndex: 'item_description',
            },
            {
              title: 'Product Size',
              key: 'size',
              dataIndex: 'size',
              render(dom, entity, index, action, schema) {
                console.log(entity)
                return (
                  entity.size_w +
                  ' x ' +
                  entity.size_d +
                  ' x ' +
                  entity.size_h +
                  ' ' +
                  entity.unit_size
                )
              },
            },
            {
              title: 'Product Weight',
              key: 'weight',
              dataIndex: 'weight',
              render(dom, entity, index, action, schema) {
                return dom + ' G'
              },
            },
            {
              title: 'Product Capacity',
              key: 'capacity',
              dataIndex: 'capacity',
            },
            {
              title: 'Inner Carton',
              key: 'inner_carton',
              dataIndex: 'inner_carton',
            },
            {
              title: 'Outer Carton',
              key: 'outer_carton',
              dataIndex: 'outer_carton',
            },
            {
              title: 'Order Q.T.Y.',
              key: 'order_qty',
              dataIndex: 'order_qty',
            },
            {
              title: 'Shipping Q.T.Y.',
              key: 'shipping_qty',
              dataIndex: 'shipping_qty',
            },
            {
              title: 'Inspected Q.T.Y.',
              key: 'inspected_qty',
              dataIndex: 'inspected_qty',
            },
            {
              title: 'Type',
              key: 'type',
              dataIndex: 'type',
            },
          ]}
        ></ProDescriptions>
      </ProCard>

      {/**
       * ANCHOR Form
       */}
      <ProCard
        layout="default"
        style={{ marginTop: 20 }}
        size={'small'}
        headerBordered
        title="Defects"
      >
        <div className="defect-form">
          {(Object.keys(currentDefectData).length > 0 ||
            isEditMode != null) && (
            <ProForm
              layout="horizontal"
              initialValues={currentDefectData}
              onFinish={async (values) => {
                const pageParams = new URLSearchParams(location.search)
                const inspection_id = pageParams.get('inspection_id')
                const item_id = pageParams.get('item_id')
                let report_status = pageParams.get('report_status')
                report_status = report_status?.toUpperCase() || ''
                if (report_status == 'REFINAL2') {
                  report_status = 'RE-FINAL2'
                }
                if (report_status == 'REFINAL3') {
                  report_status = 'RE-FINAL3'
                }
                const inspection_type = pageParams.get('inspection_type')

                console.log(values)
                if (!isEditMode) {
                  addDefectMutation.mutateAsync({
                    headers: {
                      'x-access-token': userData.token,
                    },
                    body: [
                      {
                        ...values,
                        item_id: parseInt(item_id as string),
                        inspection_id: parseInt(inspection_id as string),
                        inspection_type: inspection_type,
                        report_status_name: report_status,
                      },
                    ],
                  })
                } else {
                  updateDefectMutation.mutateAsync({
                    headers: {
                      'x-access-token': userData.token,
                    },
                    body: [
                      {
                        ...values,
                        item_id: parseInt(item_id as string),
                        inspection_id: parseInt(inspection_id as string),
                        inspection_type: inspection_type,
                        report_status_name: report_status,
                      },
                    ],
                  })
                }
              }}
              submitter={{
                // Configure the button text
                searchConfig: {
                  // resetText: 'Reset',
                  submitText: 'Update',
                },
                // Configure the properties of the button
                resetButtonProps: {
                  style: {
                    // Hide the reset button
                    // display: 'none',
                  },
                },
                submitButtonProps: {},

                // Fully customize the entire area
                render: (props, doms) => {
                  // console.log(props)
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          width: 600,
                          alignSelf: 'center',
                          // backgroundColor: 'red',
                        }}
                      >
                        <Button
                          type="default"
                          key="rest"
                          onClick={() => props.form?.resetFields()}
                          style={{ marginRight: 10 }}
                        >
                          Reset
                        </Button>
                        <Button
                          type="primary"
                          key="submit"
                          onClick={() => props.form?.submit?.()}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )
                },
              }}
            >
              <BetaSchemaForm<any>
                layoutType={'Embed'}
                // onFinish={async (values) => {
                //   console.log(values)
                // }}
                columns={columns}
              />
              {currentInspectionType == 'B' && (
                <div className="defect-type-b">
                  <BetaSchemaForm<any>
                    layoutType={'Embed'}
                    // onFinish={async (values) => {
                    //   console.log(values)
                    // }}
                    columns={columnsTypeB}
                  />
                </div>
              )}

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    width: 600,
                    border: '1px 0px 0px 0px',
                    borderTop: '1px solid #f5f5f5',
                    paddingTop: '20px',
                    alignSelf: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ProFormSelect
                    name="product_accepted"
                    label="Result"
                    valueEnum={{
                      ONHOLD: 'ON HOLD',
                      ACCEPTED: 'ACCEPTED',
                      REJECTED: 'REJECTED',
                    }}
                    rules={[{ required: true }]}
                    formItemProps={{
                      labelCol: { span: 8 },
                      wrapperCol: { span: 8 },
                    }}
                  />
                  <ProFormText
                    name="inspected_ctn_no"
                    label="INSPECTED CTN No."
                    formItemProps={{
                      labelCol: { span: 8 },
                      wrapperCol: { span: 16 },
                    }}
                  />
                </div>
              </div>
            </ProForm>
          )}
        </div>
      </ProCard>
    </PageContainer>
  )
}

export default ItemReportForm
