import { ApiResponse } from 'apisauce'
import { UpdateProfileParams } from 'models/user/user'
import { Api } from 'services/api-integrations/api'
import { getGeneralApiProblem } from 'services/api-integrations/api-problem'
import { GetSuppliersResult } from 'supplier.types'
import { CreateSupplierParams, UpdateSupplierParams } from 'supplier/supplier'

import * as Types from './api-integrations/api.types'

const API_PAGE_SIZE = 50

export class SupplierApi extends Api {
  /**
   * ANCHOR Update Supplier
   */
  async update(params: UpdateSupplierParams) {
    // const [userData] = useRecoilState(userAtom)
    const headers = params.headers

    const response: ApiResponse<any> = await this.apisauce.put(
      `/supplier`,
      params.payload,
      {
        headers: {
          'x-access-token': headers.token,
        },
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        throw new Error(`${response.data?.message}`)
      }
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }

  /**
   * ANCHOR Create Supplier
   */
  async create(params: CreateSupplierParams) {
    // const [userData] = useRecoilState(userAtom)
    const headers = params.headers

    const response: ApiResponse<any> = await this.apisauce.post(
      `/supplier`,
      params.payload,
      {
        headers: {
          'x-access-token': headers.token,
        },
      },
    )
    // console.log(response)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) {
        throw new Error(`${response.data?.message}`)
      }
    }
    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }
  /**
   * ANCHOR Gets a list of suppliers.
   */
  async list(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<GetSuppliersResult> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.post(
      `/listsupplier`,
      data.body,
      {
        headers: data.headers,
        params: data.params,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }

  /**
   * ANCHOR Delete supplier.
   */
  async delete(
    // params: Types.GetUserParams,
    data: any = {},
  ): Promise<GetSuppliersResult> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/supplier`,
      data.params,
      {
        headers: data.headers,
      },
    )

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      const raw = response.data
      return { kind: 'ok', data: raw?.result }
    } catch {
      throw new Error('bad-data')
    }
  }
}
