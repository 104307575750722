import React from 'react'

import { Breadcrumb } from 'antd'

import { Link } from 'gatsby'

function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1
  // console.log(paths)
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={'/' + paths.join('/')}>{route.breadcrumbName}</Link>
  )
}

interface SKBreadcrumbProps {
  routes: any[]
}

const SKBreadcrumb: React.FC<SKBreadcrumbProps> = ({ routes }) => {
  return <Breadcrumb itemRender={itemRender} routes={routes} />
}

export default SKBreadcrumb
