import React, { useEffect, useRef, useState } from 'react'

import {
  ClearOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import { ActionType, ProCard, ProTable } from '@ant-design/pro-components'
import { PageContainer } from '@ant-design/pro-layout'
import { Col, Empty, Popconfirm, Space, Spin, Table, message } from 'antd'

import SKBreadcrumb from 'components/sk-breadcumb'
import { saveAs } from 'file-saver'
import { Link } from 'gatsby'
import JSZip from 'jszip'
import { userAtom } from 'models/user/user-store'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { ShipmentApi } from 'services/shipment-api'

const JSZipUtils = require('jszip-utils')

interface FileViewerProps {}

const PAGE_SIZE = 5

const FileViewerPage: React.FC<FileViewerProps> = ({ location }: any) => {
  const { state = {} } = location
  const [userData] = useRecoilState(userAtom)
  const [currentFile, setCurrentFile] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [currentShipmentId, setCurrentShipmentId] = useState<any>()
  const actionRef = useRef<ActionType>()
  const [selectedFiles, setSelectedFiles] = useState<any[]>([])
  const [downloadingCount, setDownloadingCount] = useState(0)
  const [shipmentList, setShipmentList] = useState([])

  const shipmentMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.shipmentList(data)
  })

  const editSharingMutation = useMutation(async (data: any) => {
    const api = useApi(ShipmentApi)
    return api.editShare(data)
  })

  useEffect(() => {}, [])

  const request = async (params = {}, _sort = {}, _filter = {}) => {
    // console.log(params)
    if (Object.keys(params).length) {
      const pageParams = new URLSearchParams(location.search)
      const po_number = pageParams.get('po_number')
      const item_no = pageParams.get('item_no')
      params = { po_number, item_no }
    }
    // console.log(sort, filter)
    const filters = Object.keys(params)
      .filter((e) => e == 'po_number' || e == 'item_no')
      .map((e) => {
        return {
          fields: e,
          value: params[e],
          type: 'string',
        }
      })

    const result = await shipmentMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        where: [
          ...filters,
          {
            fields: 'trash_status',
            value: 'A',
            type: 'string',
          },
        ],
      },
      params: {
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
    })

    if (result.kind == 'ok' && result.data?.data?.length) {
      setShipmentList(result.data.data[0].list_shipment)
    }

    return {
      data: result.data.data[0].list_shipment,
      total: result.data.data[0].list_shipment.length,
    }
  }

  const fetchFile = (file: any) => {
    return new Promise<Blob>((resolve, reject) => {
      try {
        const file_res = fetch(file, {
          headers: { 'x-access-token': userData.token },
        })
          // console.log(typeof file_res, file_res)
          .then((res) => res.blob())
          .then((blob) => {
            // saveAs(blob, filename)
            resolve(blob)
          })
      } catch (error) {
        reject(error)
      }
    })
  }

  const download = async () => {
    // Source: https://gist.github.com/c4software/981661f1f826ad34c2a5dc11070add0f
    var zip = new JSZip()
    var count = 0
    // console.log(selectedFiles.map((e) => e.file_name_original))
    var zipFilename = 'download.zip'
    // var urls = selectedFiles.map((e) => process.env.API_URL + e.path_real)
    // message.loading('Downloading ...')

    selectedFiles.forEach(async (file) => {
      var filename = file.file_name_original
      // loading a file and add it in a zip file
      var filepath = process.env.API_URL + file.path_real

      // JSZipUtils.getBinaryContent(filepath, function (err: any, data: any) {
      //   if (err) {
      //     throw err // or handle the error
      //   }
      //   zip.file(filename, data, { binary: true })
      //   count++
      //   setDownloadingCount(count)
      //   if (count == selectedFiles.length) {
      //     zip.generateAsync({ type: 'blob' }).then(function (content) {
      //       saveAs(content, zipFilename)
      //       setDownloadingCount(0)
      //     })
      //   }
      // })

      const blob = await fetchFile(filepath)
      saveAs(blob, filename)
    })
  }

  const trashFiles = async () => {
    // console.log(selectedFiles)
    const selected_file_id = selectedFiles.map((e) => e.shipment_file_id)

    const result = await editSharingMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      body: {
        list_shipment_file_id: selected_file_id,
        status: 'I',
        option: 'edit_status',
      },
    })

    if (result.kind == 'ok') {
      console.log(result.data)
      actionRef.current?.clearSelected()
      actionRef.current?.reload()
    }
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumbRender(props, defaultDom) {
          return <SKBreadcrumb routes={props?.breadcrumb?.routes || []} />
        },
        breadcrumb: {
          routes: [
            {
              path: '/shipment-document',
              breadcrumbName: 'Shipment Document',
            },
            {
              path: '',
              breadcrumbName: 'View Files',
            },
          ],
        },
      }}
    >
      <ProCard split={'vertical'}>
        <ProCard colSpan="40%" size="small">
          <ProTable<any>
            actionRef={actionRef}
            // ANCHOR Columns
            columns={[
              {
                title: 'File name',
                key: 'file_name_original',
                dataIndex: 'file_name_original',
                width: 80,
              },
              {
                title: 'Sharing SKU',
                key: 'list_share_item',
                dataIndex: 'list_share_item',
                render(dom, entity, index, action, schema) {
                  // console.log(entity.list_share_item)
                  const list = entity.list_share_item

                  return (
                    <div style={{ height: 80, overflow: 'scroll' }}>
                      {list.map((e: any) => (
                        <>
                          <ShareAltOutlined /> {e.item_no}
                          <br />
                          {'(P.O.: ' + e.po_number + ')'}
                          <br />
                        </>
                      ))}
                    </div>
                  )
                },
              },
              {
                title: '',
                valueType: 'option',
                key: 'option',
                // width: 80,
                render: (dom, entity, index, action, schema) => {
                  console.log(entity)
                  const pageParams = new URLSearchParams(location.search)
                  const po_number = pageParams.get('po_number')
                  const item_no = pageParams.get('item_no')
                  // console.log(entity)
                  return [
                    <Link
                      to={`/shipment-document/edit-sharing?po_number=${po_number}&item_no=${item_no}&shipment_file_id=${entity.shipment_file_id}`}
                      state={entity}
                      // type="default"
                      // onClick={() =>
                      //   navigate('/shipment-document/file-viewer', { state: entity })
                      // }
                    >
                      <EditOutlined />
                    </Link>,
                  ]
                },
              },
            ]}
            rowClassName={(record) => {
              return record?.shipment_id == currentShipmentId?.shipment_id
                ? 'fileRow activeRow'
                : 'fileRow'
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  // console.log(record)
                  setCurrentFile(process.env.API_URL + record.path_real)
                  setCurrentShipmentId(record)
                },
              }
            }}
            tableAlertOptionRender={() => {
              return (
                <Col style={{ width: 80 }}>
                  <a
                    style={
                      selectedFiles.length == 0
                        ? {}
                        : {
                            color: 'green',
                          }
                    }
                    onClick={() => actionRef.current?.clearSelected()}
                    disabled={selectedFiles.length == 0}
                  >
                    <Space>
                      <ClearOutlined />
                      Clear
                    </Space>
                  </a>

                  <br />
                  <a
                    href="#"
                    onClick={download}
                    disabled={selectedFiles.length == 0}
                  >
                    <Space>
                      {downloadingCount > 0 && <Spin size="small" />}
                      {downloadingCount == 0 && <DownloadOutlined />}
                      Download
                    </Space>
                  </a>
                  <Popconfirm
                    title="Are you sure to delete these items?"
                    onConfirm={trashFiles}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a
                      href="#"
                      style={
                        selectedFiles.length == 0
                          ? {}
                          : {
                              color: 'red',
                            }
                      }
                      disabled={selectedFiles.length == 0}
                    >
                      <Space>
                        <DeleteOutlined />
                        Delete
                      </Space>
                    </a>
                  </Popconfirm>
                </Col>
              )
            }}
            request={request}
            rowSelection={{
              // type: 'radio',
              onChange(selectedRowKeys, selectedRows, info) {
                setSelectedFiles(selectedRows)
              },
              selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
              alwaysShowAlert: true,
              // defaultSelectedRowKeys: [1],
            }}
            rowKey="shipment_id"
            options={false}
            pagination={{
              current: currentPage,
              pageSize: PAGE_SIZE,
              onChange: (page) => {
                setCurrentPage(page)
              },
            }}
            search={false}
            dataSource={shipmentList}
          />
        </ProCard>
        <ProCard title="Preview">
          {'File name: ' + (currentShipmentId?.file_name_original || '-')}
          {currentShipmentId?.file_name_original && (
            <DocViewer
              style={{ height: 500 }}
              pluginRenderers={DocViewerRenderers}
              documents={[
                {
                  uri: currentFile,
                },
              ]}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  // retainURLParams: false,
                },
              }}
            />
          )}
          {!currentShipmentId?.file_name_original && (
            <Empty description={'No file selected'} />
          )}
        </ProCard>
      </ProCard>
    </PageContainer>
  )
}

export default FileViewerPage
