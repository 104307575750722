import React, { useEffect } from 'react'

import { LockOutlined, TagOutlined, UserOutlined } from '@ant-design/icons'
import { LoginForm, ProFormText } from '@ant-design/pro-form'
import { Alert, Col, Row } from 'antd'

import { Link, navigate } from 'gatsby'
import * as animationData from 'images/84726-business-meeting-animation.json'
import { LoginParams } from 'models/user/login'
import { menuAtom, userAtom } from 'models/user/user-store'
// import Lottie from 'react-lottie'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { UserApi } from 'services/user-api'
import { MenuItem } from 'user/menu'

const LoginPage = () => {
  const [_userData, setUserData] = useRecoilState(userAtom)
  const [_menuData, setMenuData] = useRecoilState(menuAtom)

  const loginMutation = useMutation(async (data: LoginParams) => {
    const userApi = useApi(UserApi)
    return userApi.login(data)
  })

  const onStartLogin = async (form: LoginParams) => {
    loginMutation.mutate(form)
  }

  useEffect(() => {
    const loginResult = loginMutation.data
    console.log(loginResult)

    if (loginResult?.kind == 'ok') {
      let userInfo = loginResult.data || {}
      let menuList =
        userInfo.menu_action?.sort(
          (a: MenuItem, b: MenuItem) => a.order - b.order,
        ) || []
      // delete userInfo.menu_action
      setMenuData(menuList)
      setUserData(userInfo)

      navigate('/summary-of-order')
    }
  }, [loginMutation.data, loginMutation.isError])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Row justify="space-around" align="middle" style={{ height: '100%' }}>
      {/**
       * ANCHOR Illustration Image
       */}
      <Col span={12} style={{ height: '100%' }}>
        <div
          style={{
            // backgroundImage: `url('https://gw.alipayobjects.com/zos/rmsportal/FfdJeJRQWjEeGTpqgBKj.png')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            // backgroundSize: '40rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {/* <Lottie
            options={defaultOptions}
            // height={400}
            width={'100%'}
            isStopped={false}
            isPaused={false}
          /> */}
          <div style={{ width: 550 }}>
            <img
              src="/images/big_logo.png"
              style={{ marginRight: 20, height: 150 }}
            />
            <img src="/images/big_name.png" style={{ height: 130 }} />
          </div>
          <div
            style={{
              paddingLeft: 170,
              fontSize: 28,

              width: 550,
              lineHeight: 1.2,
            }}
          >
            A WORLDWIDE SOURCING PROVIDER AND QUALITY CONTROLER OF HOME
            FURNISHINGS AND DECORATIVE PRODUCTS
          </div>
        </div>
      </Col>

      {/**
       * ANCHOR Login Form
       */}
      <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{loginMutation.isError}</div>
        <LoginForm
          // logo="/images/logo2.png"
          // title="SIAM KARAT"
          subTitle={`Sign in with your account`}
          message={
            loginMutation.isError && (
              <Alert
                message={'Incorrect username or password!'}
                type="error"
                showIcon
                style={{ marginBottom: 20 }}
              />
            )
          }
          submitter={{
            searchConfig: { submitText: 'Sign in' },
            submitButtonProps: {
              loading: loginMutation.isLoading,
            },
          }}
          onFinish={onStartLogin}
        >
          <ProFormText
            name="username"
            fieldProps={{
              size: 'large',
              prefix: <UserOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Username'}
            rules={[
              {
                required: true,
                message: 'Please enter username!',
              },
            ]}
          />
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            placeholder={'Password'}
            rules={[
              {
                required: true,
                message: 'Please enter your password!',
              },
            ]}
          />
        </LoginForm>
        <div
          style={{
            width: '80%',
            alignSelf: 'center',
          }}
        >
          <Col
            sm={24}
            md={24}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2>
              <Link to="https://2021.siamkarat-qc.com">
                <TagOutlined /> 2021.siamkarat-qc.com
              </Link>
            </h2>

            <Alert
              type="warning"
              message="Access is restricted to Siam Karat International Co.,Ltd only.
            Only individuals currently assigned an account on this Web
            Application by Siam Karat may access data on this Application. All
            information stored in this Application System is the property of
            Siam Karat and is subject to all the protection accorded
            intellectual property."
              style={{ width: '80%', marginTop: 30 }}
            ></Alert>
          </Col>
        </div>
      </Col>
    </Row>
  )
}

export default LoginPage
